import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import WorkoutReading from './workoutReading';
import PhysicalActivity from './physicalActivity';
import ProblemDetail from './problemDetail';
import { Critical } from './critical';
import { appConfig } from "../../common/constants";
import Immunization from './immunization';
import D_StudyProgress from './dstudyprogress'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomizedProgressBars from './roundedProgress';
import Medication from '../../img/Medication.png'
import Allergy from '../../img/Allergies.png'
import MC from '../../img/MC.png'
import Lab from '../../img/Labdata1.png'
import BackToTop from '../../common/gototopBtn'
import moment from 'moment';
import ECG from '../../img/dashboard_icons/ECG.png'
import LoaderGif from '../../img/dashboard_icons/Animation05.gif'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Dashboard = (props) => {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [loader, setLoader] = useState(false);
  let [pdataset, setPdataset] = useState([])
  let [adataset, setAdataset] = useState([])
  let [adataActive, setAdataActive] = useState("")
  let [mdataset, setMdataset] = useState([])
  let [mdataActive, setMdataActive] = useState("")
  let [procedure, setProcedure] = useState([])
  let [procedureActive, setProcedureActive] = useState("")
  let [labdata, setLabdata] = useState([])
  let [immunizationdata, setImmunizationdata] = useState([])
  let [staticData, setStaticData] = useState(false)
  let [physicalActData, setPhysicalActData] = useState('')
  const resourceTypes = ['Condition', 'MedicationOrder', 'Observation', 'Immunization', 'AllergyIntolerance', 'Procedure', 'Condition_CMS', 'MedicationRequest_CMS', 'Observation_CMS', 'Immunization_CMS', 'Procedure_CMS']
  // ['Condition_CMS','MedicationRequest_CMS','Observation_CMS','Immunization_CMS','Procedure_CMS']
  useEffect(() => {
    if (props.app === "Patient") {
      var url = new URL(window.location.href)
      if (url.searchParams.get('code')) {
        CMSHealthSubscription(url.searchParams.get('code'), props.data || props.formValues.patientdata)
      } else if (url.searchParams.get('success')) {
        oneUpHealthSubscription(props.data || props.formValues.patientdata)
      } else {
        fetchEventData(resourceTypes);
      }
    }
    fetchEventData(resourceTypes);
  }, [])

  const fetchEventData = async (names) => {
    setProgress(true)
    let requests
    if (props.app !== "Patient") {
      //used for registered studies patient click
      if (props.data?.patient360 === "Y") {
        requests = names.map(name => fetch(`${appConfig.BaseUrl}patientdetail?patientId=${props.data.ehrPatientId}&eventType=${name}&from=0&size=100`));
      } else if (props.data.patientId != "" || null || undefined) {
        requests = names.map(name => fetch(`${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=${name}&from=0&size=100`));
      } else {
        requests = names.map(name => fetch(`${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=${name}&from=0&size=100`));
      }
    } else {
      requests = names.map(name => fetch(`${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=${name}&from=0&size=100&patientId=${props.data.subjectId ? props.data.subjectId : props.data.patientId}`));
    }
    Promise.all(requests)
      .then(responses => {
        // all responses are resolved successfully
        // for (let response of responses) {
        //   alert(`${response.url}: ${response.status}`); // shows 200 for every url
        // }
        return responses;
      })
      // map array of responses into an array of response.json() to read their content
      .then(responses => Promise.all(responses.map(r => r.json())))
      // all JSON answers are parsed: "users" is the array of them
      .then(data => {
        console.log(data, "alldata")

        if (data.length > 0) {
          var conditiondata = []
          var medicationdata = []
          var labdata = []
          var immunidata = []
          var proceduredata = []
          data.map((item, i) => {
            //Conditions
            if (i == 0 || i == 6) {
              var data = []
              var objActive = []
              var objRest = []
              item.data.map((subitem, j) => {
                var obj = {
                  name: subitem?.code?.text,
                  status: i == 6 ? subitem?.clinicalStatus?.coding[0].code : subitem?.clinicalStatus,
                  startDate: moment(subitem?.onsetDateTime).format('Do MMM YYYY'),
                  // datasource: i == 0 ? "Others" : "CMS"
                }
                // str.log(obj, i, j, "obj")
                if (obj.status === "active") {
                  objActive.push(obj)
                } else {
                  objRest.push(obj)
                }
                // console.log(objActive,"conditiondata")

              })
              data = [...objActive, ...objRest]
              conditiondata = [...data,...conditiondata]
              console.log(data, "inside conditions")
            }
            //Medications
            if (i == 1 || i == 7) {
              var data = []
              var objActive = []
              var objRest = []
              item.data.map((subitem, j) => {
                var obj = {
                  name: subitem?.medicationCodeableConcept?.text,
                  status: subitem?.status,
                  startDate: moment(subitem?.dateWritten).format('Do MMM YYYY'),
                  // datasource: i == 1 ? "Others" : "CMS"
                }
                // console.log(obj,i,j,"medication")
                if (obj.status === "active") {
                  objActive.push(obj)
                } else {
                  objRest.push(obj)
                }


              })
              // setMdataset(data)
              data = [...objActive, ...objRest]
              medicationdata = [...data,...medicationdata]
              if (i == 1) {
                setMdataActive(item.activeCount != undefined ? item.activeCount : 0)
              }
            }

            //lab data
            if (i == 2 || i == 8) {
              var data = []
              { console.log(i, item.data.length, "labdata") }
              item.data.map((subitem, j) => {
                if (i == 2 && subitem?.category?.coding[0].code === "laboratory" || i == 8 && subitem?.category[0]?.coding[0].code === "laboratory") {
                  var obj = {
                    name: subitem?.code?.text,
                    value: subitem?.valueQuantity?.value != undefined ? ((subitem?.valueQuantity?.value).toFixed(2) + ' ' + subitem?.valueQuantity?.unit) : '',
                    startDate: moment(subitem?.effectiveDateTime).format('Do MMM YYYY')
                  }
                  data.push(obj)
                }
              })
              console.log(data, "inside labdata")
              labdata = [...data,...labdata]

            }
            //immunizations
            if (i == 3 || i == 9) {
              var data = []
              item.data.map((subitem, j) => {
                var obj = {
                  date: i == 3 ? new Date(subitem?.date).getDate() : new Date(subitem?.occurrenceDateTime).getDate(),
                  month: i == 3 ? month_names_short[new Date(subitem?.date).getMonth()] : month_names_short[new Date(subitem?.occurrenceDateTime).getMonth()],
                  year: i == 3 ? new Date(subitem?.date).getFullYear() : new Date(subitem?.occurrenceDateTime).getFullYear(),
                  vaccine: subitem?.vaccineCode?.text,
                  description: subitem?.vaccineCode?.text
                }
                data.push(obj)
              })
              console.log(i, data, "inside immuniz")
              immunidata = [...data,...immunidata]

            }
            //Allergies
            if (i == 4) {
              var data = []
              item.data.map((subitem, i) => {
                var obj = {
                  name: subitem?.substance?.text,
                  severity: subitem?.criticality,
                  startDate: moment(subitem?.onset).format('Do MMM YYYY'),
                  status: subitem?.status
                }
                data.push(obj)
              })
              setAdataset(data)
              setAdataActive(item.activeCount != undefined ? item.activeCount : 0)
            }
            //Procedure
            if (i == 5 || i == 10) {
              var data = []
              var objActive = []
              var objRest = []
              item.data.map((subitem, j) => {
                var obj = {
                  name: subitem.code.text,
                  startDate: subitem?.performedPeriod?.start ? moment(subitem?.performedPeriod?.start).format('MMM Do YYYY') : "",
                  endDate: subitem?.performedPeriod?.end ? moment(subitem?.performedPeriod?.end).format('MMM Do YYYY') : "",
                  status: subitem.status
                }
                if (obj.status === "active") {
                  objActive.push(obj)
                } else {
                  objRest.push(obj)
                }

              })
              data = [...objActive, ...objRest]
              console.log(data, "inside procedures")
              proceduredata = [...data,...proceduredata]
              if (i == 5) {
                setProcedureActive(item.activeCount != undefined ? item.activeCount : 0)
              }
            }
          });
          setPdataset(conditiondata)
          setMdataset(medicationdata)
          setProcedure(proceduredata)
          setImmunizationdata(immunidata)
          setLabdata(labdata)
          console.log(proceduredata.length, "proc", immunidata.length, "immi", labdata.length, "lab")
        }
        setProgress(false)
      })
  }
  const oneUpHealthSubscription = async (data) => {
    setLoader(true)
    fetch(`${appConfig.BaseUrl}1uphealthsubscription`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "email": data.email })
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        setLoader(false)
        // if (responseData.status == '200' || responseData.status == '504'){
        fetchEventData(resourceTypes)
        // }else{
        //   alert('timeout')
        // }

      })
  }
  const CMSHealthSubscription = (code, data) => {
    setLoader(true)
    fetch(`${appConfig.BaseUrl}cmspull?hekmaMail=${data.email}&code=${code}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        setLoader(false)
        fetchEventData(resourceTypes)
      })
  }
  useEffect(() => {
    setProgress(true)
    if (props.app === "Patient") {
      let patientid = (props.data.subjectId != "null" && props.data.subjectId != null) ? props.data.subjectId : props.data.patientId
      fetch(`${appConfig.BaseUrl}healthkit?id=${patientid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(responseData => {

          if (responseData && responseData.data && responseData.data.length > 0) { setPhysicalActData(responseData.data[responseData.data.length - 1].data) } else { setPhysicalActData("") }
          setProgress(false)
        })
    }

  }, [props.data.email])
  let aColumns = [
    { title: 'Allergy', field: 'name' },
    {
      title: 'Severity',
      field: 'severity',
      render: rowData => <Critical text={rowData.severity === "CRITL" || "CRITU" || "CRITH" ? "critical" : rowData.severity} />
    },
    { title: 'Start Date', field: 'startDate', }
  ]

  let pColumns = [
    { title: 'Conditions', field: 'name' },
    {
      title: 'Status ',
      field: 'status',
      render: rowData => <Critical text={rowData.status} />
    },
    {
      title: 'Date of Diagnosis', field: 'startDate',
      cellStyle: {
        width: "33%",
        textAlign: "left"
      }
    },
    // { title: 'Data Source', field: 'datasource' }
  ]
  let lColumns = [
    { title: 'Name', field: 'name' },
    { title: 'Value', field: 'value' },
    { title: 'Date', field: 'startDate', }
  ]
  let mColumns = [
    { title: 'Medication', field: 'name' },
    // { title: 'Dosage', field: 'dosage' },
    // { title: 'Regimen', field: 'regimen' },
    { title: 'Status', field: 'status' },
    { title: 'Date', field: 'startDate', },
    // { title: 'Data Source', field: 'datasource', }
  ]

  // let mdataset = [
  //   { name: 'medication1', surname: 'severe', birthYear: '04/05/2020' },
  //   { name: 'medication2', surname: 'mild', birthYear: '04/05/2020' },
  //   { name: 'medication3', surname: 'moderate', birthYear: '04/05/2020' },
  // ]
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <img src={LoaderGif} alt="datafetching" /> */}
      </div>}
      {
        loader && <div className="fullScreen bg-w">
          <img src={LoaderGif} alt="datafetching" />
        </div>
      }
      <section className="dashboardDetails">
        {
          staticData ?
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="pcard">
                  <D_StudyProgress />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="row">
                  <section className="col-12 col-sm-6">
                    <div className="dcard">
                      <PhysicalActivity data={physicalActData.length > 0 ? physicalActData : []} />
                    </div>
                  </section>
                  <section className="col-12 col-sm-6">
                    <div className="dcol4main">
                      <section className="dcard dcol4">
                        <WorkoutReading
                          text='Heart Rate'
                          icon="fas fa-heartbeat"
                          reading={physicalActData.length > 0 ? (physicalActData[0].value).toString() : ""}
                          unit={'BPM'}
                        />
                      </section>
                      <section className="dcard dcol4">
                        <WorkoutReading
                          text='ECG'
                          // icon="fas fa-fire"
                          reading={'1200'}
                          unit={'KCal'}
                          src={ECG}
                        />
                      </section>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            : ""
        }
        <div className="row">

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <section className="">

              {mdataset.length > 0 ?
                <ProblemDetail
                  header="Medications"
                  icon={Medication}
                  iColor=''
                  thColor='#ebf5ff'
                  number={mdataActive}
                  text={mdataset.length > 1 ? "Total Medications" : "Medication"}
                  description=" Active Prescriptions"
                  columns={mColumns}
                  dataset={mdataset}
                  app={props.app}
                /> : <section className="problemDetail dcard">
                  <h6 >Medications</h6>

                  <p>No Data Found</p>
                </section>}
            </section>
            <div className="mt-4">
              {pdataset.length > 0 ?
                <ProblemDetail
                  header="Medical Conditions"
                  icon={MC}
                  iColor=''
                  thColor='#ffebf9'
                  number={pdataset.filter(x => x.status === "active").length}
                  text={pdataset.length > 1 ? "Total Conditions" : "Condition"}
                  description="Ongoing Conditons"
                  columns={pColumns}
                  dataset={pdataset}
                  app={props.app}
                /> : <section className="problemDetail dcard">
                  <h6 >Medical Conditions</h6>
                  <p>No Data Found</p>
                </section>}
            </div>
            <div className="mt-4 mb-3">
              {labdata.length > 0 ?
                <ProblemDetail
                  header="Lab Reports"
                  icon={Lab}
                  iColor=''
                  thColor=''
                  number={labdata.length}
                  text="Lab Report"
                  description="Records found"
                  columns={lColumns}
                  dataset={labdata}
                  app={props.app}
                /> : <section className="problemDetail dcard">
                  <h6 >Labdata</h6>
                  <p>No Data Found</p>
                </section>}
            </div>

          </div>


          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="row">
              <section className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
                <div className="dcard">
                  <PhysicalActivity data={physicalActData.length > 0 ? physicalActData : []} />
                </div>
              </section>
              <section className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2 centeredAlign">
                <div className="dcard dcol4main">
                  <section className="dcol4 col-12 col-sm-12">
                    <WorkoutReading
                      text='Heart Rate'
                      icon="fas fa-heartbeat"
                      reading={physicalActData.length > 0 ? (physicalActData[0].value).toString() : ""}
                      unit={'BPM'}
                    />
                  </section>
                </div>
                <div className="dcard dcol4main">
                  <section className="dcol4 col-12 col-sm-12 mt-2">
                    <WorkoutReading
                      text='ECG'
                      // icon="fas fa-fire"
                      // reading={'54'}
                      unit={'Sinus Rhythm'}
                      src={ECG}
                    />
                  </section>
                </div>
              </section>
            </div>
            <div className="mt-4">
              {adataset.length > 0 ?
                <ProblemDetail
                  header="Allergies"
                  icon={Allergy}
                  iColor=''
                  thColor='#f4ebff'
                  number={adataActive}
                  text={adataset.length > 1 ? "Total Allergies" : "Allergy"}
                  description="Ongoing Allergies"
                  columns={aColumns}
                  dataset={adataset}
                  app={props.app}
                /> : <section className="problemDetail dcard">
                  <h6 >Allergies</h6>
                  <p>No Data Found</p>
                </section>}
            </div>
            <div className="colType mt-4">
              <section className="dcard">
                <Immunization data={immunizationdata} app={props.app} />
              </section>
              <section className=" mt-4 my-4">
                {procedure.length > 0 ?
                  <ProblemDetail
                    header="Procedures"
                    icon={Medication}
                    iColor=''
                    thColor='#ebf5ff'
                    number={procedureActive}
                    text={procedure.length > 1 ? "Total Procedures" : "Procedure"}
                    description=" Active Procedures"
                    columns={mColumns}
                    dataset={procedure}
                    app={props.app}
                  /> : <section className="problemDetail dcard">
                    <h6 >Procedures</h6>

                    <p>No Data Found</p>
                  </section>}
              </section>
            </div>
          </div>
        </div>
        {/* <div className="row mt-3">
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" style={{ minWidth: "350px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>S. NO</TableCell>
                    <TableCell>ID</TableCell>
                    <>
                      <TableCell>Type</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {physicalActData.length > 0 && physicalActData.map((item, i) =>
                    <>
                      <TableRow>
                        <TableCell rowSpan={item?.data.length + 1}>{i + 1}</TableCell>
                        <TableCell rowSpan={item?.data.length + 1}>{item.id}</TableCell>
                      </TableRow>
                      {item.data.length > 0 && item?.data.map(subitem =>
                        <TableRow>
                          <TableCell>{subitem.type}</TableCell>
                          <TableCell>{subitem.value}</TableCell>
                          <TableCell>{subitem.startDate}</TableCell>
                          <TableCell>{subitem.endDate}</TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>

              </Table>

            </TableContainer>
          </div>
        <div className="col-md-4">
             <pre>{
            JSON.stringify(physicalActData, null, 2)}</pre>
          </div>
        </div> */}

      </section>
      <React.Fragment>
        <BackToTop idd={"back-to-top-anchor"} />
      </React.Fragment>
    </>
  )

}


// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form,
//     state
//   }
// }
const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
    //     UpdateFormField: (obj) => {
    //       return dispatch(
    //         {
    //           type: 'UpdateFormField',
    //           value: obj.value,
    //           name: obj.name
    //         }
    //       )
    //     },
    //     updateErrorObj: (obj) => {
    //       return dispatch(
    //         {
    //           type: 'updateErrorObj',
    //           flag: obj.flag,
    //           name: obj.name,
    //           text: obj.text
    //         }
    //       )
    //     },
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Dashboard));
