import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MHSearchField from '../mhSearchField';
import VaccineCard from '../vaccineCard';
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function Year(props) {
  return (
    <>
      <p className="secondary-header vyear">{props.year}</p>
    </>
  )
}

function Immunizations(props) {

  let [progress, setProgress] = useState(false);
  let [resData, setResData] = useState('');
  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if(props.data?.patient360==="Y"){
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?patientId=${props.data.ehrPatientId}&eventType=Immunization&from=0&size=1000`
      }else if (props.data.patientId != "" || null || undefined) {
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?hekmaMail=${props.data.email}&eventType=Immunization&from=0&size=1000`
      } else {
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?patientId=${props.data.subjectId}&eventType=Immunization&from=0&size=1000`
      }

    } else {
      url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?hekmaMail=${props.data.email}&eventType=Immunization_CMS&from=0&size=1000&patientId=${props.data.subjectId}`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          //console.log(responseData, "immunizations")
          let response = builddata(responseData);
          setResData(response);
        } else {
          //console.log('no data found')
        }
      })

  }, [])


  function getYear(date) {
    return new Date(date).getFullYear();
  }

  function getvaccines(item) {
    return item.vaccineCode.text;
  }

  function buildFinalData(response) {
    let arrResponse = [];
    let obj = {};
    response.map((item, inx) => {
      if (obj[item[inx].year]) {
        let vaccineObj = {};
        vaccineObj.name = item[inx].vaccines || '';
        vaccineObj.sd = item[inx].date || '';
        obj[item[inx].year]['vaccines'].push(vaccineObj);
      } else {
        obj[item[inx].year] = {};
        obj[item[inx].year].year = item[inx].year;
        obj[item[inx].year]['vaccines'] = [];
        let vaccineObj = {};
        vaccineObj.name = item[inx].vaccines || '';
        vaccineObj.sd = item[inx].date || '';
        obj[item[inx].year]['vaccines'].push(vaccineObj);
      }
      arrResponse.push(obj);
    })
    return arrResponse[0];
  }

  function builddata(response) {
    //console.log(response,"build")
    let arrResponse = [];
    response.data.map((item, inx) => {
      let obj = {};
      let year = getYear(item.occurrenceDateTime);
      obj[inx] = {};
      obj[inx]['year'] = year;
      obj[inx]['date'] = moment(item.occurrenceDateTime).format('MMM Do YYYY');
      obj[inx]['vaccines'] = getvaccines(item);
      arrResponse.push(obj);
    })
    let finalresponse = buildFinalData(arrResponse);
    return finalresponse;
  }
  //console.log(resData,"resData")
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/immunizations/add')
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {resData ?
        <>
          <section>
            <MHSearchField
              title="Vaccines"
              label="Search Vaccines"
              // btnName="ADD VACCINE"
              addBtnText="ADD VACCINE"
              app={props.app}
              handleChange={handleChange}
            />
          </section>


          {
            Object.keys(resData).reverse().map((itemkey, inx) => {
              //console.log(itemkey, resData[itemkey])
              return (
                <>
                  <div>
                    <Year year={itemkey} />
                    <section className="row">
                      {
                        resData[itemkey].vaccines.map((vaccine, vi) => {
                          return (
                            <div className="col-12 col-sm-4">
                              <VaccineCard
                                title={vaccine.name}
                                sd={vaccine.sd}
                              />
                            </div>
                          )
                        })
                      }
                    </section>
                  </div>
                </>
              )
            })
          }
        </> : "No immunizations found"
      }
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Immunizations));



