import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi, apiCall, oldgenericApi } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../../globalcomponents/formElements';
import SimpleModal from '../../../../globalcomponents/simplemodal'
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { appConfig } from '../../../../common/constants';
// import { checkPermission } from '../../../../common/utils';
import Materialtable from '../../../../globalcomponents/materialtable'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Criteria from './criteria'
import LightTooltipPatient from '../../../../globalcomponents/tooltipPatientmatch';
import Tooltip from '@material-ui/core/Tooltip';
import Dashboard from '../../../../sponsorApp/components/marketplace/dashboard'
import FunnelOrder from "../../../../globalcomponents/funnelorderpage";
function AccountsGrid(props) {
  // let isAddAccount = checkPermission('Account_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [open, setOpen] = useState(false);
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  // let [pricingType, setPricingType] = useState('')
  let [criteriaData, setCriteriaData] = useState('')
  let [show, setShow] = useState(false)
  let [dashboardData, setDashboardData] = useState("")
  let [patientsData, setPatientsData] = useState("")
  let [insightsData, setInsightsData] = useState("")
  let [showFunnel, setShowFunnel] = useState(false)
  let [order, setOrder] = useState("*")
  const [funnelData, setFunnelData] = useState("")
  const [funnelCount, setFunnelCount] = useState("")

  useEffect(() => {
    // callSponsorsApi("biogen")
    if (props.formValues.registeredStudiesData) {
      setData(props.formValues.registeredStudiesData)
    } else {
      callComposeApi()
    }
    if (props.formValues.funnelPatients) {
      setShow(true)
      setDashboardData(props.formValues.opensquareDashboarddata)
    }
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);
  const showDashboard = (e, rowData) => {
    e.preventDefault();
    setDashboardData(rowData)
    props.UpdateFormField({ name: "opensquareDashboarddata", value: rowData })
    setShow(true)
  }
  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const showInsights = async (e, rowData) => {
    e.preventDefault();
    console.log(rowData, "rowdata")
    callFunnelApi(rowData, "*")
  }

  const callFunnelApi = async (rowData, order) => {
    let requestBody = {
      query: `query{
        getComposeFunnelDetailforSitewithFilter(studyId:"${rowData.studyId}",siteId:"${rowData.siteId}",  patientGender:"*", patientAgeMin:"*",patientAgeMax:"*",patientRace:"*",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"*", patientEducation:"*", siteName:"*", siteCity:"*", siteState:"*", siteZipcode:"*", siteCountry: "*",order:"${order}")
          {
               nctId
                criteriaOrder
                criteriaText
                criteriaBaseOrder
                ieType
                criteriaIndex
                funnelCount
                matchCount
                totalPatientCount
          }
        }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getComposeFunnelDetailforSitewithFilter', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getComposeFunnelDetailforSitewithFilter.length > 0) {
        var data = res.data.getComposeFunnelDetailforSitewithFilter
        var data1 = data.map((item, i) => { return [item.criteriaText + '(<b>' + item.ieType.toUpperCase() + '</b>)', parseInt(item.funnelCount), "#ff0000", item.criteriaBaseOrder, item.ieType.toUpperCase()] })
        // data1.unshift(['<b>Total Patients</b>', parseInt(data[0].totalPatientCount), "#ff0000", "0"])
        setFunnelData(data1)
        var count = data.reduce((acc, cv) => {
          return acc + parseInt(cv.funnelCount);
        }, 0)
        setFunnelCount(data[0].totalPatientCount)
        setInsightsData(rowData)
        setShowFunnel(true)
      }
    }
  }

  const callComposeApi = async () => {

    let requestBody = {
      query: `query {
        getComposeDetailsPercent(siteId:"${props.formValues.autosite.sponsorAccountId}", studyId:"*", patientGender:"*", patientAgeMin:"*",patientAgeMax:"*",patientRace:"*",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"*", patientEducation:"*", siteName:"*", siteCity:"*", siteState:"*", siteZipcode:"*", siteCountry:"*",leadStatus:"*",highbelow:"*",highend:"*",midbelow:"*",midend:"*", lowbelow:"*", lowend:"*")
        {
             studyId
              siteId
              siteName
              siteCity
              siteState
              siteCountry
            patientCount
            requestId
            leadStatus
            priceType
            price
            totalPrice
            percentCount {
              siteId
              bucket
              count
          }
        }
      }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getComposeDetailsPercent', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getComposeDetailsPercent.length > 0) {
        // setData(res.data.getComposeDetailsPercent)
        const newData = await res.data.getComposeDetailsPercent.map(v => (
          { ...v, pricingType: { "name": v.priceType, "value": v.priceType }, totalprice: v.totalPrice, price: v.price ? parseInt(v.price) : "", edit: v.totalPrice ? true : false, updated: false }

        ))
        props.UpdateFormField({ name: "registeredStudiesData", value: newData })
        setData(newData)

      }
    }
  }

  // const callSponsorsApi = async (querystring) => {
  //   if (querystring.length > 3) {
  //     setProgress(true);
  //     fetch(appConfig.marketplaceBaseApi + `?sponsor=` + querystring + '&pir=Y',
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         }
  //       })
  //       .then(res => {
  //         if (res.status !== 200 && res.status !== 201) {
  //           throw new Error('Failed!');
  //         }
  //         return res.json();
  //       })
  //       .then(responseData => {
  //         console.log(responseData, "data")
  //         const newData = responseData.data.map(v => ({ ...v, "patientCount": Math.floor(Math.random() * 100), pricingType: "", totalprice: "", price: "" }))
  //         setData(newData)
  //         setProgress(false);
  //       })
  //   } else {
  //     setData([])
  //   }
  // }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    event.preventDefault()
    // await props.setSidebarActiveEl('accounts');
    // props.UpdateEntireForm(rowData);
    // props.history.push('/site/accountDetails');
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      // callSponsorsApi(valSearch);
      await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
      // await props.updateHeaderSearchValue({ value: "" })
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    // callSponsorsApi("");
    setValSearch("")
    fetch(appConfig.marketplaceBaseApi,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        setData(responseData.data)

      })

  }


  const Columns = [
    {
      title: "NCTId", field: "studyId",
    },
    {
      title: 'Count', field: 'patientCount', cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // textAlign: 'center'
      }, render: rowData => parseInt(rowData.patientCount) > 0 ? <div style={{ paddingLeft: "3%" }}><LightTooltipPatient data={JSON.stringify(rowData)} showPatients={showPatients} percentData={rowData.percentCount} setProgress={setProgress} enableonclick={true}>{rowData.patientCount} </LightTooltipPatient></div> : rowData.patientCount
    },
    {
      title: "Metrics", field: "", cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // marginRight:"50px"
        // textAlign: 'center'<i class="fa-sharp fa-solid fa-filter-list"></i>
      }, render: rowData => <Tooltip title="Dashboard" arrow><i className="fa fa-solid fa-chart-line" style={{ color: "#23416F", paddingLeft: "12%" }} alt="Dashboard" onClick={(e) => showDashboard(e, rowData)} /></Tooltip>
    },
    {
      title: "Insights", field: "", cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // marginRight:"50px"
        // textAlign: 'center'
      }, render: rowData => <Tooltip title="Insights" arrow><i className="fa fa-solid fa-filter" style={{ color: "#23416F", paddingLeft: "13%" }} alt="Insights" onClick={(e) => showInsights(e, rowData)} /></Tooltip>
    },
    {
      title: 'Price($)', field: 'price', cellStyle: { textAlign: "right" }, render: rowData => <TextField
        fullWidth
        value={rowData.price}
        onChange={(e, value) => handlePrice(e, rowData, data)}
      />
    },
    {
      title: 'Pricing Type', field: 'pricingType', render: rowData =>
        <Autocomplete
          id="controlled-demo"
          options={[{ name: "per patient", value: "per patient" }, { name: "flat", value: "flat" }]}
          getOptionLabel={option => option.name ? option.value : ""}
          value={rowData.pricingType}
          disableClearable
          onChange={(e, value) => pricingTypeEvent(e, value, rowData, data)}
          renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
        />
    },

    {
      // title: "Total Price($)",
      title: <div style={{ marginLeft: '20px'}}>Total Price($)</div>,
      field: 'Total Price($)',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: "center",
      }, render: data => data.totalprice ? numberWithCommas(parseInt(data.totalprice)) : ""
    },

  ]
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const showPatients = (e, type, text) => {
    e.preventDefault()
    props.history.push('/site/studypatients')
    props.UpdateFormField({ name: "studypatients", value: type })
    props.UpdateFormField({ name: "studypatientstext", value: "Matched Patients" + text })
    //    setOpen(true)
    //    setPatientsData(type)
    // console.log(type,"type")
  }
  console.log(open, "true")
  const criteria = (e, data) => {
    e.preventDefault()
    // if (data.FullStudy.RefinedCriteria != undefined) {
    //   setCriteriaData(data?.FullStudy?.RefinedCriteria)
    // } else {
    //   setCriteriaData('')
    // }
    props.UpdateFormField({ name: "mpstudy", value: data })
    props.history.push('/site/opensquare/studydetails')
    // setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const handlePrice = (e, rowData, data) => {
    setData(data.map(item => item.tableData.id === rowData.tableData.id ? { ...item, "price": e.target.value, pricingType: "", totalprice: "" } : item))
    // setPricingType('')
  }
  const pricingTypeEvent = (e, value, rowData, data) => {
    // setPricingType(value.value)
    if (value.value === "per patient") {
      setData(data.map(item => item.tableData.id === rowData.tableData.id ? { ...item, pricingType: value, "totalprice": rowData.price * rowData.patientCount, "updated": true } : item))
    }
    if (value.value === "flat") {
      setData(data.map(item => item.tableData.id === rowData.tableData.id ? { ...item, pricingType: value, "totalprice": rowData.price, "updated": true } : item))
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    for (var i = 0; i < data.length; i++) {
      if (data[i].updated) {
        if (!data[i].edit) {
          setProgress(true)
          let requestBody = `
    mutation 
    {
      openSquareRegistered(studyId:"${data[i].studyId}",
      siteId:"${props.formValues.autosite.sponsorAccountId}",status:"",patientCount:"${data[i].patientCount}",priceType:"${data[i].pricingType.value}",price:"${data[i].price}", totalPrice:"${data[i].totalprice}") {
        code
        type
        message
        attributes
      }  
    }
        `;

          requestBody = JSON.stringify({ query: requestBody });
          let res = await oldgenericApi('openSquareRegistered', requestBody);
          setProgress(false);
          if (res && res.data.openSquareRegistered.code === '200') {
            callComposeApi()
            // alert('added')
            // propsobj.history.push('/site/trailmatch/nonRegisteredstudies')
          } else {
            console.log('Error!')
          }
        }

        else {
          setProgress(true)
          let requestBody1 = `
      mutation 
      {
        openSquareLeadResponse(requestId:"${data[i].requestId}",
        status:"Update",patientCount:"${data[i].patientCount}",price:"${data[i].price}",priceType:"${data[i].pricingType.value}", totalPrice:"${data[i].totalprice}") {
          code
          type
          message
          attributes
        }  
      }
          `;

          requestBody1 = JSON.stringify({ query: requestBody1 });
          let res1 = await oldgenericApi('openSquareLeadResponse', requestBody1, "");
          setProgress(false);
          if (res1 && res1.data.openSquareLeadResponse.code === '200') {
            callComposeApi()

            // propsobj.history.push('/site/trailmatch/nonRegisteredstudies')
          } else {
            console.log('Error!')
          }
        }
      }
    }
  }

  const handleSubmitFunnelOrder = (e, orderdata) => {
    var order = orderdata.map((item) => { return item[3] })
    // order.shift()
    setOrder(order.toString())
    callFunnelApi(insightsData, order)
    setFunnelData("")
    setFunnelCount("")
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      {/* <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ACCOUNTS</h1> */}
      {/* {isAddAccount === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
          </div> : ""} */}
      {/* </div> */}
      {(show || showFunnel) ? "" : <div className="listing-wrap hekmaGrid mt-4">
        <div className="card">
          {/* <SimpleModal header="Patients List" open={open} onClose={onClose} width={"70%"}>
            <div className="col-md-12">
            <Materialtable
              columns={patientColumns}
              data={patientsData.length > 0 ? patientsData : []}
              handleRowClick={handleRowClick}
            />
            </div>
          </SimpleModal> */}
          <div className="hekmaGridBox">
            {/* <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>

                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X

                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div> */}
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
              search={true}
            />
            {data.length > 0 &&
              <div className="fr">
                <button className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Save</button>&nbsp;

              </div>
            }
          </div>
        </div>
      </div>
      }
      {show &&
        <div>
          <div className="row mt-0">
            <div className="col-md-12 mb-2 justify-content-end" >
              <h1 className="mr-auto primary-header" style={{ float: "left" }}>{"Patients Count:" + dashboardData.patientCount}</h1>

              <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={() => setShow(false)} style={{ float: "right", marginTop: "5px" }}>
                BACK</button>
            </div>
          </div>
          <Dashboard data={dashboardData} app="Site" />
        </div>
      }
      {showFunnel && <>
        <FunnelOrder funnelCount={funnelCount} progress={progress} funnelData={funnelData} handleSubmitFunnelOrder={handleSubmitFunnelOrder} setShowFunnel={setShowFunnel} setOrder={setOrder} setInsightsData={setInsightsData} />
      </>}
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsGrid));