import React, { useState, useEffect, Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, styled } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment'
import { genericApi, apiCall, oldgenericApi } from '../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';
// import LighttooltipPatientmatch from '../../../globalcomponents/tooltipPatientmatch'
// import Clock from '../img/emailtemplate/clock.svg'
// import User from '../img/emailtemplate/user.svg'
// import Desc from '../img/emailtemplate/content.svg'
// import Meeting from '../img/videochat/meeting.svg'
// import Recording from '../img/videochat/Video-call.svg'

const LighttooltipPatientmatch = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid skyblue"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    maxWidth: "none",
    // "&:before": {
    border: "1px solid skyblue",
    // },
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    marginLeft: "-10px"
  },
}))(Tooltip);

const LightTooltipPatient = (props) => {
  const [lfmatch, setLfmatch] = useState('')
  const [gfmatch, setGfmatch] = useState('')
  const [gsmatch, setGsmatch] = useState('')
  const [data, setData] = useState([])
  const [progress, setProgress] = useState(false)
  useEffect(() => {
    // callGetPercentageMatch(JSON.parse(props.data), props)
    if (props?.percentData.length > 0) {
      var data = props.percentData.find(o => o.bucket === '1')
      var data1 = props.percentData.find(o => o.bucket === '2')
      var data2 = props.percentData.find(o => o.bucket === '3')
      console.log(data, data1, data2, "Dataa")
      setData([data, data1, data2])
    }
  }, [props?.percentData])

  const callApi = async (e, a, b) => {
    e.preventDefault()
    if (props.enableonclick) {
      props.setProgress(true)
      var data = await callGetPercentageMatch(JSON.parse(props.data), props)
      if (data.length > 0) {
        var lfmatch = data.filter(i => (parseInt(i.percentMatch) >= 50 && parseInt(i.percentMatch) <= 80))
        var gfmatch = data.filter(i => (parseInt(i.percentMatch) > 80 && parseInt(i.percentMatch) <= 99))
        var gsmatch = data.filter(i => parseInt(i.percentMatch) == 100)
        // setLfmatch(lfmatch.length > 0 ? lfmatch : [])
        // setGfmatch(gfmatch.length > 0 ? gfmatch : [])
        // setGsmatch(gsmatch.length > 0 ? gsmatch : [])
        props.setProgress(false)
        props.showPatients(e, a === "gsmatch" ? gsmatch : a === "gfmatch" ? gfmatch : lfmatch, b)
      }
      else {
        alert('no data found')
      }
    }
  }
  const callGetPercentageMatch = async (data, props) => {
    const { genderField = "", age = "", raceField = "", language = "", education = "", city = "", stateField = "", zip = "", countryField = null } = props
    setProgress(true)
    let requestBody1 = {
      query: `query {
        getComposeDetails(siteId:"${data.siteId}", studyId:"${data.studyId}", patientGender:"${genderField != "" ? genderField.value : "*"}", patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}")
        {
             studyId
              siteId
              siteName
              siteCity
              siteState
              siteCountry
              patientId
              patientFirstName
              patientMiddleName
              patientLastName
              patientEmail
              patientCity
              patientState
              patientZip
              patientCountryCode
              patientCountry
              patientGender
              patientAge
              percentMatch
              patient360
              ehrPatientId
        }
    }`
    }
    requestBody1 = JSON.stringify(requestBody1);
    // setProgress(true);
    let res1 = await genericApi('getComposeDetails', requestBody1);
    setProgress(false);
    if (res1) {
      if (res1.data.getComposeDetails.length > 0) {
        var responseData = res1.data.getComposeDetails
        return responseData
      }
      else {
        return []
      }
    }
  }
  return (
    <div className="doctooltip" style={{ overflow: 'hidden' }}>
      <LighttooltipPatientmatch TransitionComponent={Zoom} interactive title={
        <div className="" style={{ maxWidth: "500px" }}>
          <div className="" style={{ textAlign: "justify", fontSize: "12px" }}>
            <div style={{ display: "flex", justifyContent: "space-around", width: "400px" }}>
              <div style={{ cursor: props.enableonclick ? "pointer" : "default" }} onClick={(e) => callApi(e, "gsmatch", "(100%)")}> 100% Match : <b>{data.length > 0 && (data[0] == undefined ? "0" : data[0].count)}</b></div>&nbsp;
              <div style={{ cursor: props.enableonclick ? "pointer" : "default" }} onClick={(e) => callApi(e, "gfmatch", "(81%-99%)")}> (81 - 99)% Match :  <b>{data.length > 0 && (data[1] == undefined ? "0" : data[1].count)} </b></div>&nbsp;
              <div style={{ cursor: props.enableonclick ? "pointer" : "default" }} onClick={(e) => callApi(e, "lfmatch", "(50%-80%)")} > (50-80)% Match :  <b>{data.length > 0 && (data[2] == undefined ? "0" : data[2].count)} </b></div>
            </div>

          </div>
        </div>

      } placement="bottom" arrow>
        <div className="fc-event-title"
          style={{
            fontWeight: "100", marginRight: "4px", cursor: "pointer"
          }}
        > {props.children}</div>
      </LighttooltipPatientmatch >
    </div >
  )
}
export default LightTooltipPatient;
