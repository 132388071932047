// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import matchSorter from 'match-sorter';
import SearchIcon from '@material-ui/icons/Search';
import BgImg from '../img/opensquarebg.jpg'
import $ from 'jquery'
const NCTIDs = (propsobj) => {
  const [loading, setLoading] = React.useState(false)

  const filterOptions = (options, { inputValue }) => {
    // let keys = propsobj.keys;
    return matchSorter(options, inputValue, { keys: ["value"] })
  }
  const renderOption = option => {

    return (
      <>
        {option["value"]}
      </>
    )
  }
  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.inputValue) {
      return option.inputValue;
    }
    return option && option["value"]

  }
  const autoHandleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue, "valu sele")
  }
  const openPir = () => {
    propsobj.UpdateFormField({ name: "Inclusion", value: [] })
    propsobj.UpdateFormField({ name: "Exclusion", value: [] })
    propsobj.history.push('/account/pir')
  }
  const onSubmit = (e) => {
    e.preventDefault()
  }
  return (
    // <div className="opensquare p-5" style={{
    //   backgroundImage: `url(${BgImg})`, backgroundSize: 'cover',
    //   width: "100%",
    //   height: "auto"
    // }}>

    //   <div className="row pb-3" >


    //     <div className="col-md-12 clinicaltrailinput" >
    //       <h2 style={{ color: "#23416E", marginBottom: "0px" }}>Clinical Open Square</h2>
    //       <p style={{ color: "#666" }}>Connect Patients to Cure</p>
    //       <div className="autodropdown">
    //         <form onSubmit={onSubmit}>
    //           <Autocomplete
    //             id="asynchronous-demo"
    //             // style={{ width: 300 }}
    //             // open={nctid.value.length > 2}
    //             value={nctid}
    //             fullWidth
    //             // onOpen={() => {
    //             //   setOpen(true);
    //             // }}
    //             // onClose={() => {
    //             //   setOpen(false);
    //             // }}
    //             getOptionSelected={(option) => option.value}
    //             getOptionLabel={getOptionLabel}
    //             options={options}
    //             clearOnEscape={true}
    //             autoHighlight={true}
    //             debug={true}
    //             disableClearable
    //             onChange={autoHandleChange}
    //             filterOptions={filterOptions}
    //             // loading={loading}
    //             renderOption={renderOption}
    //             renderInput={(params) => (
    //               <TextField
    //                 {...params}
    //                 // label="Clinical Trail ID"
    //                 variant="outlined"
    //                 className="inputfield"
    //                 placeholder="Enter Clinical Trail ID (Eg: NCT00000XXX)"
    //                 InputProps={{
    //                   ...params.InputProps,
    //                   endAdornment: (
    //                     <React.Fragment>
    //                       {loading ? <CircularProgress color="inherit" size={20} /> : null}
    //                       {params.InputProps.endAdornment}
    //                     </React.Fragment>
    //                   ),
    //                 }}
    //               />
    //             )}
    //           />
    //         </form>

    //         <span className="text fl mt-3" onClick={openPir}>Advanced Search</span>
    //         <button type="submit" onClick={onSubmit} className="btn btn-primary-blue btn-rounded btn-small fr mt-3" >Submit</button>
    //       </div>
    //     </div>
    //     <div className="col-md-4 infodiv">
    //       {/* <div className="info">
    //         <div>Explore <b>39052</b> research studies in all <b>50</b> states and in <b>19</b> countries</div><br />
    //         <div>Clinicaltrails.gov is a resource provided by the U.S National Library of Medicine</div><br />
    //         <div>Important: Listing a study doesn't mean it has been evaluated by U.S Fedral Government. Read our declaimer for studies</div><br />
    //         <div>Clinicaltrails.gov is a resource provided by the U.S National Library of Medicine</div><br />
    //         <div>Important: Listing a study doesn't mean it has been evaluated by U.S Fedral Government. Read our declaimer for studies</div>
    //       </div> */}
    //     </div>
    //   </div>
    // </div>
    <>
      {/* "linear-gradient(90deg,#bbd9f9,#f4f4fa,#bbd9f9)" */}
      <div className="opensqData">
        <h2 style={{ color: "#23416E", marginBottom: "0px" }}>{propsobj.mainHeader}</h2>
        <p style={{ color: "#666" }}>{propsobj.subHeader}</p>
        <div className="autodropdown">
          <form onSubmit={propsobj.onSubmit}>
            <Autocomplete
              id="asynchronous-demo"
              style={{ width: 400, background: "white" }}
              className="opensquaredropdown"
              // open={nctid.value.length > 2}
              value={propsobj.nctid}
              // fullWidth
              // onOpen={() => {
              //   setOpen(true);
              // }}
              // onClose={() => {
              //   setOpen(false);
              // }}
              getOptionSelected={(option) => option.value}
              getOptionLabel={getOptionLabel}
              options={propsobj.options}
              clearOnEscape={true}
              autoHighlight={true}
              debug={true}
              disableClearable
              onChange={propsobj.autoHandleChange}
              filterOptions={filterOptions}
              // loading={loading}
              renderOption={renderOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Clinical Trail ID"
                  variant="outlined"
                  className="inputfield"
                  placeholder={propsobj.placeholder}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {propsobj.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </form>

          <span className="text fl mt-3 cr" style={{ color: "#666", fontSize: "16px" }} onClick={propsobj.openPir}>{propsobj.subtext}</span>
          {propsobj.threshold && <Autocomplete
            id="asynchronous-demo"
            options={[{ name: "Low", value: "low" }, { name: "Medium", value: "medium" }, { name: "High", value: "high" }]}
            getOptionLabel={option => option.value ? option.value : ""}
            value=""
            fullWidth
            clearOnEscape={true}
            filterSelectedOptions
            autoHighlight={true}
            debug={true}
            disableClearable
            onChange={(event, newValue) => propsobj.handleThreshold(newValue)}
            renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3 mt-3"
            style={{background:"white",width:"50%",float:"left",padding:"10px"}}
              placeholder="Threshold value" />}
          />}
          <button type="submit" onClick={propsobj.onSubmit} className="btn btn-primary-blue btn-rounded btn-small fr mt-3" >Submit</button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NCTIDs));