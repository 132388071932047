// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../common/constants';
import matchSorter from 'match-sorter';
import SearchIcon from '@material-ui/icons/Search';
import BgImg from '../../../img/BG2.jpg'
import $ from 'jquery'
import NCTIDs from '../../../globalcomponents/nctidDropdowns'
import Loader from '../../../common/loader';
const NCTPage = (propsobj) => {
  const [data, setData] = React.useState('');
  const [options, setOptions] = React.useState([{label:"NCT00029848",value:"NCT00029848"},{label:"NCT00284167",value:"NCT00284167"},{label:"NCT00391196",value:"NCT00391196"},{label:"NCT00396448",value:"NCT00396448"},{label:"NCT00434525",value:"NCT00434525"},{label:"NCT00434655",value:"NCT00434655"},{label:"NCT00546455",value:"NCT00546455"},{label:"NCT05763875",value:"NCT05763875"},{label:"NCT05662332",value:"NCT05662332"},{label:"NCT05669599",value:"NCT05669599"},{label:"NCT05764642",value:"NCT05764642"},{label:"NCT05872620",value:"NCT05872620"},{label:"NCT05882045",value:"NCT05882045"},]);
  const [nctid, setNctId] = React.useState({ label: "", value: "", info: "" })
  const [loading, setLoading] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [thresholdValue, setThresholdValue] = React.useState({})

  React.useEffect(() => {
    // setLoading(true)
    var sponsor = "biogen"
    let hostName = window.location.hostname;

    if (hostName == appConfig.host1) {
      sponsor = "biogen"
    }
    if (hostName == appConfig.host2) {
      sponsor = "Pfizer"
    }
    // + '&from=100'
    // let data=  { "inclusion_text": "1. Subjects must be overweight (BMI 27- 50 kg/m2)\n2. Subjects must have type 2 diabetes mellitus",
    // "exclusion_text": "1. Pregnancy\n2. Serious or unstable current or past medical conditions"}
    // console.log(data.inclusion_text)
    // (async () => {
    //   fetch(appConfig.marketplaceBaseApi + '?sponsor=' + sponsor + '&pir=Y',
    //     {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     })
    //     .then(res => {
    //       if (res.status !== 200 && res.status !== 201) {
    //         throw new Error('Failed!');
    //       }
    //       return res.json();
    //     })
    //     .then(responseData => {
    //       let nctids = responseData.data.map((item, i) => {
    //         let obj = {};
    //         obj['value'] = item.FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
    //         obj['label'] = item.FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
    //         // obj['info'] = item.FullStudy.Study.ProtocolSection.EligibilityModule;
    //         // obj['pir'] = item.FullStudy?.PIR;
    //         // obj['Inclusion'] = item.FullStudy?.RefinedCriteria?.inclusion
    //         // obj['Exclusion'] = item.FullStudy?.RefinedCriteria?.exclusion
    //         return obj;
    //       })
    //       setLoading(false)
    //       setOptions(nctids)
    //       setData(responseData.data)
    //     })
    // })();
//   let data=  {
//     "inclusion_text": "1. Subjects must be overweight (BMI 27- 50 kg/m2)\n2. Subjects must have type 2 diabetes mellitus",
//     "exclusion_text": "1. Pregnancy\n2. Serious or unstable current or past medical conditions",
//     "patient_info_nct": {
//         "nct_id": {
//             "28352": "NCT00391196",
//             "28482": "NCT00391196",
//             "28578": "NCT00391196",
//             "28874": "NCT00391196",
//             "29075": "NCT00391196",
//             "29357": "NCT00391196",
//             "29577": "NCT00391196",
//             "29614": "NCT00391196",
//             "29834": "NCT00391196",
//             "29845": "NCT00391196",
//             "30145": "NCT00391196",
//             "30152": "NCT00391196"
//         },
//         "patient_ids": {
//             "28352": "300e2bc4-6eaf-4db3-23a6-32bb20be9f80",
//             "28482": "0b5f006d-3391-2b50-8d0b-d6e2e452f044",
//             "28578": "bcb8b56b-1d32-3fae-e38b-78fd190054f1",
//             "28874": "164efa7e-1ac6-ed36-b7e8-a317e02843d9",
//             "29075": "638328b6-196c-6a50-1fbc-a744aeadd2d8",
//             "29357": "c5572b86-b2e7-d2c0-4053-96c6f654fe11",
//             "29577": "71dba440-09d6-c50a-0475-02b9280c4800",
//             "29614": "8198d6a7-7c1a-ac04-d0f6-3e7462df1440",
//             "29834": "a20b8267-abcb-b093-5536-7be1293994af",
//             "29845": "4a17e015-b26f-2a3b-e5d9-5b3e5e530ea9",
//             "30145": "6a104967-e15f-c300-86a1-b66eec212008",
//             "30152": "39c6c834-03a3-8947-7677-337e76043c26"
//         }
//     },
//     "predicted_count": 12,
//     "original_nct": {
//         "NCT00284167": 35678,
//         "NCT00396448": 33040,
//         "NCT00546455": 17179,
//         "NCT00434525": 16389,
//         "NCT00434655": 15484,
//         "NCT05763875": 12758,
//         "NCT00391196": 1996,
//         "NCT00029848": 1988,
//         "NCT05764642": 1289,
//         "NCT05669599": 303,
//         "NCT05662332": 214,
//         "NCT05872620": 61,
//         "NCT05882045": 39
//     },
//     "accuracy": 0.6,
//     "download_path": "./whole_data/files_download/output_drop_down_accuracy_NCT00391196_top_high.csv"
// }
//     console.log(data.patient_info_nct.nct_id,"data.patient_info_nct.nct_id")
//     propsobj.UpdateFormField({name:"newInclusion",value:data.inclusion_text})
//     propsobj.UpdateFormField({name: "newExclusion",value:data.exclusion_text})
//     propsobj.UpdateFormField({name:"nctids",value:data.patient_info_nct.nct_id})  
//     propsobj.UpdateFormField({name:"patientids",value:data.patient_info_nct.patient_ids})
  }, []);

  const autoHandleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue, "valu sele")
    // setLoading(true)
    // fetch(appConfig.marketplaceBaseApi + '?id=' + newValue.value,
    //   {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }
    //   })
    //   .then(res => {
    //     if (res.status !== 200 && res.status !== 201) {
    //       throw new Error('Failed!');
    //     }
    //     return res.json();
    //   })
    //   .then(responseData => {
    //     // let nctids = responseData.data.map((item, i) => {
    //     let obj = {};
    //     obj['value'] = responseData.data[0].FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
    //     obj['label'] = responseData.data[0].FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
    //     obj['info'] = responseData.data[0].FullStudy.Study.ProtocolSection.EligibilityModule;
    //     obj['pir'] = responseData.data[0].FullStudy?.PIR;
    //     obj['Inclusion'] = responseData.data[0].FullStudy?.RefinedCriteria?.inclusion
    //     obj['Exclusion'] = responseData.data[0].FullStudy?.RefinedCriteria?.exclusion
    //     //   return obj;
    //     // })
    //     console.log(obj)
    //     setLoading(false)
    //     setNctId(obj)
    //   })
    setNctId(newValue.value)

  }
  const handleThreshold = async (obj) => {
    setThresholdValue(obj.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(nctid,thresholdValue,"hjjh")
    if (nctid) {
      setLoader(true)
      fetch(process.env.REACT_APP_NEWPIR + 'nct_search/', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          number: nctid,
          threshold: thresholdValue
        })
      })
        .then(response => response.json())
        .then(data => {
          setLoader(false)
          if (data) {
            console.log(data,"nctdata")
            propsobj.UpdateFormField({name:"newInclusion",value:data.inclusion_text})
            propsobj.UpdateFormField({name: "newExclusion",value:data.exclusion_text})
            propsobj.UpdateFormField({name:"nctids",value:data.patient_info_nct.nct_id})  
            propsobj.UpdateFormField({name:"patientids",value:data.patient_info_nct.patient_ids})
            propsobj.history.push('/account/newpir/searchcriteria')
          }
        }).catch(error =>{ setLoader(false); console.error(error)});
     
      // propsobj.history.push('/account/newpir/searchcriteria')
    }
  }

  return (
    <>
      <Loader progress={loader} />
      <NCTIDs mainHeader="Ask Yekka" subHeader="Quickly Find Patients to Cure" onSubmit={onSubmit} options={options} value={nctid} placeholder="Enter clinical Trial ID (Eg: NCT00000XXX)" autoHandleChange={autoHandleChange} loading={loading} subtext="" threshold={true} handleThreshold={(obj) => handleThreshold(obj)} />

    </>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NCTPage));