import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateDetail from '../dateDetail';
import TestNameDetail from '../testNameDetail';
import TestDetailCard from '../testDetailCard';
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function CarePlan(props) {
  const [title, setTitle] = useState('')
  const [descData, setDescData] = useState({})
  let [progress, setProgress] = useState(false);
  const [data, setData] = useState([])
  useEffect(() => {
    setProgress(true);
    fetch(`https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?patientId=${props.data.subjectId}&eventType=CarePlan&from=0&size=1000&patientId=${props.data.subjectId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          //console.log(responseData, "medications")
          var finaldata = []
          responseData.data.map((item, i) => {
            var obj = {
              "testTitle": item.category[0].text,
              "date": item.period.start,
              "activities": item.activity
            }
            finaldata.push(obj)
          })
          setData(finaldata)
        } else {
          //console.log('no data found')
        }
      })

  }, [])

  const clickHandler = (e, index, title) => {
    e.preventDefault();
    setDescData(data[index])
    setTitle(title)
  }
  
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ?
          <>
            <section>
              <MHSearchField
                title="CarePlan"
                label="Search CarePlan Data"
                btnName="ADD CAREPLAN"
              />
            </section>
            <section className="labDetailPage">
              <section className="labTableRow">
                <div className="row">
                  <div className="col-sm-6">
                    <section className="row">
                      <div className="col-12 col-sm-4">
                        <span><b>Date</b></span>
                      </div>
                      <div className="col-12 col-sm-8">
                        <span><b>Category</b></span>
                      </div>
                    </section>
                  </div>
                  <div className="col-sm-6">
                    <span><b>Activities</b></span>
                  </div>
                </div>
              </section>
              <section className="">
                <div className="row">

                  <div className="col-sm-6 datedescription">
                    {data.map((item, i) => (
                      <section className="row mb-3">
                        <div className="col-12 col-sm-4">
                          <DateDetail
                            year={new Date(item.date).getFullYear()}
                            mdate={month_names_short[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate()}
                            icon={'fas fa-flask'}
                            icolor='#863DD0'
                          />
                        </div>
                        <div className="col-12 col-sm-8">
                          <TestNameDetail
                            title={item.testTitle}
                            clickHandler={(e) => clickHandler(e, i, item.testTitle)}
                            color="#863DD0"
                          />
                        </div>
                      </section>
                    ))}
                  </div>

                  <div className="col-sm-6">
                    {data.map((item, i) => (
                      <section className="mediCard vaccineCard" style={{ margin: '0px' }}>
                        {item.activities.map((subitem, j) => (
                          <>
                            <div className="paper">
                              <span className="mr-3">{j+1}. {subitem.detail.code.text}</span>
                              <span><b>Status:</b> {subitem.detail.status}</span>
                            </div>
                          </>
                        ))}
                      </section>
                    ))}
                    {/*  */}
                  </div>
                </div>
              </section>
            </section>
          </> : "No data found"
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(CarePlan));



