import React, { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from './hooks/useAgora';
import MediaPlayer from './components/MediaPlayer';
import './styles.scss';
import $ from 'jquery'
import CallIcon from '../img/videochat/call.svg'
import Record from '../img/videochat/recording.svg'
import RecordStop from '../img/videochat/stop.gif'
// import VideoIcon from '../img/videochat/video.svg'
import CallCut from '../img/videochat/call-cut.svg'
import ShareIcon from '../img/videochat/share2.svg'
import RecordAni from '../img/videochat/recording-icon.gif'
import Chat from './chat'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { appConfig } from "../common/constants";
import { oldgenericApi } from '../common/apiconfig';
import {
  useParams
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from 'react-redux';

const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });
let options = {
  uid: '',
  key: process.env.REACT_APP_AGORA_KEY,
  secret: process.env.REACT_APP_AGORA_SECRET
};
var localTrackState = {
  videoTrackMuted: false,
  audioTrackMuted: false
}

function Call(props) {
  const reduxdispatch = useDispatch();
  console.log(props.state,"svideo")
  const [height, setHeight] = useState("200px")
  const [width, setWidth] = useState("180px")
  const [progress, setProgress] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [startData, setStartData] = useState('')
  const [show, setShow] = useState(false)
  const [appId, setAppId] = useState("")
  const [token, setToken] = useState("")
  let [v_panel_width, setVwidth] = useState('');
  let [v_panel_height, setVheight] = useState('65vh');
  const [record_uid, setrecord_uid] = useState('')
  let [recording, setRecording] = useState(false)
  let [recordedData, setRecordedData] = useState([])
  var date = moment(new Date()).format('YYYY-MM-DD')
  let { channel } = useParams()

  useEffect(() => {
    callAgoraCred()
    // callGetToken()
    getDimension();
    props.UpdateFormField({ name: "hidestudytab", value: false })
    setrecord_uid((Math.floor(100000 + Math.random() * 900000)).toString())
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }

  }, [])


  const getDimension = () => {
    let video_panel = document.querySelector("#video-panel")
    let v_width = video_panel.clientWidth - 30;
    // let v_height = video_panel.clientHeight;
    //v_height = v_height - (49+45);
    setVwidth(v_width);

  }

  // useEffect(() => {
  //   console.log(token,appId)
  //   if (token.length > 0 && appId.length>0) {
  //     join(appId, options.channel, token)
  //   }
  // }, [appId,token])

  const callAgoraCred = async () => {
    // var data = await fetchData("agora?env=dev")
    var data = await fetchData("agora?env="+process.env.REACT_APP_ENVTYPE)
    console.log(data, "data")
    if (data) {
      setAppId(data.appID)
      callGetToken(data.appID)
    }
  }

  const callGetToken = async (appId) => {
    setProgress(true)
    // var data1 = await fetchData("agora?env=dev&channel=" + channel)
    var data1 = await fetchData("agora?env="+process.env.REACT_APP_ENVTYPE+"&channel=" + channel)
    if (data1) {
      setToken(data1.token)
      join(appId, channel, data1.token)
      setProgress(false)
    }
  }
  const fetchData = (queryUrl) => {
    return fetch(`${appConfig.BaseUrl + queryUrl}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        return responseData
      })
  }
  const {
    localAudioTrack, localVideoTrack, leave, join, screenshare, joinState, remoteUsers
  } = useAgora(client);

  const muteaudio = (e) => {
    if (!localTrackState.audioTrackMuted) {
      muteAudio();
    } else {
      unmuteAudio();
    }
  };

  const mutevideo = (e) => {
    if (!localTrackState.videoTrackMuted) {
      muteVideo();
    } else {
      unmuteVideo();
    }
  }

  async function muteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setMuted(true);
    localTrackState.audioTrackMuted = true;
    if (localTrackState.audioTrackMuted) {
      $("#mute-audio").find('i').toggleClass('fa-microphone').toggleClass('fa-microphone-alt-slash');
    } else {
      $("#mute-audio").find('i').toggleClass('fa-microphone-alt-slash').toggleClass('fa-microphone');
    }
  }

  async function muteVideo() {
    if (!localVideoTrack) return;
    await localVideoTrack.setMuted(true);
    localTrackState.videoTrackMuted = true;
    if (localTrackState.videoTrackMuted) {
      $("#mute-video").find('i').toggleClass('fa-video').toggleClass("fa-video-slash");
    } else {
      $("#mute-video").find('i').toggleClass('fa-video-slash').toggleClass("fa-video");
    }
  }

  async function unmuteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setMuted(false);
    localTrackState.audioTrackMuted = false;
    if (localTrackState.audioTrackMuted) {
      $("#mute-audio").find('i').toggleClass('fa-microphone').toggleClass('fa-microphone-alt-slash');
    } else {
      $("#mute-audio").find('i').toggleClass('fa-microphone-alt-slash').toggleClass('fa-microphone');
    }
    // $("#mute-audio").find('i').toggleClass('fa-microphone-alt-slash').toggleClass('fa-microphone');
  }

  async function unmuteVideo() {
    if (!localVideoTrack) return;
    await localVideoTrack.setMuted(false);
    localTrackState.videoTrackMuted = false;
    if (localTrackState.videoTrackMuted) {
      $("#mute-video").find('i').toggleClass('fa-video').toggleClass("fa-video-slash");
    } else {
      $("#mute-video").find('i').toggleClass('fa-video-slash').toggleClass("fa-video");
    }
    // $("#mute-video").find('i').toggleClass('fa-video-slash').toggleClass("fa-video");
  }
  // Find active speakers
  // client.enableAudioVolumeIndicator();
  // client.on("volume-indicator", volumes => {
  //     volumes.forEach((volume) => {
  //         console.log(`UID ${volume.uid} Level ${volume.level}`);
  //         if (options.uid == volume.uid && volume.level > 5) {
  //             $("#local").css({
  //                 "box-shadow": "0 2px 4px 0 #0C9DFD, 0 2px 5px 0 #0C9DFD"
  //             });
  //         } else if (options.uid == volume.uid && volume.level < 5) {
  //             $("#local").css({
  //                 "box-shadow": "none"
  //             });
  //         }
  //         if (options.uid != volume.uid && volume.level > 5) {
  //             $("#player-" + volume.uid).css({
  //                 "box-shadow": "0 2px 4px 0 #0C9DFD, 0 2px 5px 0 #0C9DFD"
  //             });
  //         } else if (options.uid != volume.uid && volume.level < 5) {
  //             $("#player-" + volume.uid).css({
  //                 "box-shadow": "none"
  //             });
  //         }
  //     });
  // })
  // async function screenshare(){
  //     const screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  //     await screenClient.join(appId, channel, token,uid);

  //     const screenTrack = await AgoraRTC.createScreenVideoTrack({
  //         encoderConfig: "1080p_1",
  //       }, "enable");
  //     await screenClient.publish(screenTrack);
  // }
  const fullscreen = (event) => {
    event.preventDefault();
    console.log(window.screen.height)
    console.log(window.screen.width)
    // console.log(window.screen.availHeight)
    // console.log(window.screen.availWidth)
    setHeight(window.screen.height)
    setWidth(window.screen.width)
    setFullScreen(true)
  }

  var auth = options.key + ":" + options.secret;
  var encodedString = btoa(auth);

  const record = async (event) => {
    // event.preventDefault();
    // alert('record')
    setRecording(true)
    reduxdispatch({ type: 'videoRecReq', value: true })
    let url = `https://api.agora.io/v1/apps/${appId}/cloud_recording/acquire`

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encodedString
      },
      body: JSON.stringify({ "cname": channel, "uid": record_uid, "clientRequest": {} }),
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        console.log(responseData, "Res")
        if (responseData.resourceId) {

          let startUrl = `https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${responseData.resourceId}/mode/mix/start`
          fetch(startUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + encodedString
            },
            body: JSON.stringify({
              "cname": channel,
              "uid": record_uid,
              "clientRequest": {
                "token": token,
                "recordingConfig": {
                  "maxIdleTime": 300,
                  // "streamTypes": 2,
                  // "audioProfile": 1,
                  // "channelType": 0, 
                  // "videoStreamType": 0, 
                  // "transcodingConfig": {
                  //     "height": window.screen.height, //600 
                  //     "width": window.screen.width, //360
                  //     "bitrate": 500, 
                  //     "fps": 15, 
                  //     "mixedVideoLayout": 1,
                  //     "backgroundColor": "#FF0000"
                  //     },
                  "transcodingConfig": {
                    "width": 640,
                    "height": 360,
                    "fps": 30,
                    "bitrate": 600,
                    "mixedVideoLayout": 1
                  }

                },
                "recordingFileConfig": {
                  "avFileType": [
                    "hls",
                    "mp4"
                  ]
                },
                "storageConfig": {
                  "vendor": 1,
                  "region": 3,
                  "bucket": "hekma-patient-videos/" + localStorage.getItem("loggedInUser") + "/" + date + "/",
                  "accessKey": "AKIAVMIRHJNN7WT2L7YQ",
                  "secretKey": "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",
                },
              }
            }),
          })
            .then(res => {
              if (res.status !== 200 && res.status !== 201) {
                throw new Error('Failed!');
              }
              return res.json();
            })
            .then(data => {
              console.log(data, "startdata")
              setShow(true)
              setStartData(data)
            })
        }
      })


  }
  const recordstop = () => {
    console.log(startData, "Data")
    setShow(false)
    setProgress(true)
    let queryurl = `https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${startData.resourceId}/sid/${startData.sid}/mode/mix/query`
    fetch(queryurl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encodedString
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(data => {
        console.log(data, "query")
      })
    let stopurl = `https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${startData.resourceId}/sid/${startData.sid}/mode/mix/stop`

    fetch(stopurl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Basic ' + encodedString
      },
      body: JSON.stringify({ "cname": channel, "uid": record_uid, "clientRequest": {} }),
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(data => {
        console.log(data, "stopdata")
        setRecording(false)
        // setStartData(data)
        if (data.serverResponse.uploadingStatus === "uploaded") {
          setRecordedData(data.serverResponse.fileList)
          setProgress(false)
        }
      })
  }
  const callCtmsStorageApi = async (info, chat) => {
    console.log(info, chat, "--------")
    var videofile = ''
    if (info?.length > 0) {
      var path = localStorage.getItem("loggedInUser") + "/" + date
      videofile = `{buArea: "videocall",type:"Video", detail1: "${path}",detail2:"${info[0].fileName}"}`;
    }
    let requestBody = `
    mutation {
      putArtifactWithSupport(
        id: "${props.formValues.appointmentData.appointmentId}",
        name: "${channel}", 
        summary:${JSON.stringify(JSON.stringify(chat))},
        type:"${date}",
        category:"",
        language:"",
        version:"",
        supportDetails:[${[videofile]}]
           ) {
          code
          type
          message
          attributes
      }
    }
  `;
    setProgress(true);
    requestBody = JSON.stringify({ query: requestBody });
    let res = await oldgenericApi('putArtifactWithSupport', requestBody);
    if (!res) {
      //console.log('Error on update profile');
      //setApiError(true);
      //setTimeout(function () { setApiError(false) }, 1000 * 30);
    } else {
      if (res.data.putArtifactWithSupport.code == "200") {
        console.log("stored in ctms")
        setProgress(false);
        leave()
        props.history.goBack()
        // getRecording()
      }
    }
  }
  console.log(client, remoteUsers, "Ddddd")

  const Enablescreenshare = async (appId, channel, token, uid) => {
    await screenshare(appId, channel, token, uid)
    // $("#mute-audio").find('i').removeClass().addClass('fas fa-microphone');
    // $("#mute-video").find('i').removeClass().addClass('fas fa-video');
    // var audioclass = $('#mute-audio').find('i').attr('class');
    // var videoclass = $('#mute-video').find('i').attr('class');
    // console.log(audioclass,videoclass,audioclass === "fas fa-microphone")
    // if (audioclass === "fas fa-microphone") {
    //   await localAudioTrack.setMuted(false);
    //   localTrackState.audioTrackMuted = false;
    // } else {
    //   await localAudioTrack.setMuted(true);
    //   localTrackState.audioTrackMuted = true;
    // }
    // if (audioclass === "fas fa-video") {
    //   await localVideoTrack.setMuted(false);
    //   localTrackState.videoTrackMuted = false;
    // } else {
    //   await localVideoTrack.setMuted(true);
    //   localTrackState.videoTrackMuted = true;
    // }

    // console.log(audioclass, videoclass, "uuuuuuuuuu");
  }

  const leavecall = async () => {
    // if(recording){
    //   await recordstop()
    // }
    callCtmsStorageApi(recordedData, props.formValues.allchat)

  }
  return (

    <div className='call mt-2 pb-3 mb-3' style={{ background: "#f5f5f5" }}>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <h1 className="mr-auto primary-header mb-3">Welcome to Hekma Meeting</h1>

      <div className="videocontainer row mb-3">

        <div className="video-container col-md-8 col-xs-7" id="video-panel">
          {joinState && <>
            <div className="header" >
              <div className="text-left">
                {/* {show &&
                  <span><img src={RecordAni} alt="Recording Started" /></span>
                } */}
                <span style={{ color: "#fff" }}>
                  <img src={CallIcon} alt="icon" height="22px" />
                </span>
                {show && <>
                  <span><img src={RecordAni} alt="Recording Started" /> recording ...</span>

                  <Tooltip title="Stop Recording" arrow>
                    <span className="cr" onClick={() => recordstop()}><img src={RecordStop} alt="stop" height="23px" /></span>
                  </Tooltip>
                </>
                }
                {/* {!show &&
                  <span className="headertext">Ongoing call</span>
                } */}
                <Tooltip title="Share screen" arrow>
                  <span className="fr cr" onClick={() => { Enablescreenshare(appId, channel, token, client.uid) }}>
                    {/* <i className="fas fa-share-square"></i> */}
                    <img src={ShareIcon} alt="sharescreen" />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="video">
              {remoteUsers.length > 1 ?
                <>
                  <div className='local-player-wrapper' style={{ marginRight: "12px" }}>
                    <div className='local-player-text'>{joinState && localVideoTrack ? client._joinInfo?.optionalInfo + '(You)' : ''}</div>
                    <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width="100px" height="100px" local={true} id="local" ></MediaPlayer>
                  </div>
                  <section className="remote-people">
                    {remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
                      {/* <div className='remote-player-text'>{client._joinInfo?.optionalInfo}</div> */}
                      <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width={width} height={height} id="remote" ></MediaPlayer>
                    </div>))}
                  </section>
                </> :
                <>
                  <div className='local-player-wrapper' className={remoteUsers.length == 1 ? 'localcss' : ""} >
                    {/* <div className='local-player-text'>{localVideoTrack && "Host"}{joinState && localVideoTrack ? `(${ client.uid })` : ''}</div> */}
                    <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width={remoteUsers.length == 1 ? "80px" : v_panel_width + 'px'} height={remoteUsers.length == 1 ? "100px" : v_panel_height} local={true} id="local-2" ></MediaPlayer>
                  </div>
                  <section className="remote-people">
                    {remoteUsers.map(user => (<div className='remote-player-wrapperr' key={user.uid}>
                      {/* <div className='remote-player-text'>{`remoteVideo(${ user.uid })`}</div> */}
                      <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width={v_panel_width} height="66vh" id="remote-2" ></MediaPlayer>
                    </div>))}
                  </section>
                </>
              }
            </div>

            <div className="footer">
              <Tooltip title="End Call" arrow>
                <span style={{ color: 'red', cursor: "pointer" }} onClick={() => { leavecall() }}>
                  <img src={CallCut} alt="end call" height="22px" />
                </span>
              </Tooltip>
              {!show &&
                <Tooltip title="Record" arrow>
                  <span className="fl mr-3 cr" onClick={() => record()}><img src={Record} alt="Record" height="23px" /></span>
                </Tooltip>
              }

              {/* <Tooltip title="Mute Call" arrow> */}
              <span className="fr cr" id="mute-video" onClick={() => mutevideo()}><i className="fas fa-video"></i></span>
              {/* </Tooltip> */}
              {/* <Tooltip title="End Call" arrow> */}
              <span className="fr" id="mute-audio" onClick={() => muteaudio()}><i className="fas fa-microphone"></i></span>
              {/* </Tooltip> */}
            </div>
          </>}
        </div>
        {joinState &&
          <div className="chat-container col-md-4 col-xs-5" id="chat-panel" style={{paddingLeft:"0px"}}>
            <Chat clientId={client.uid} channel={{
              "name": channel,
              "clientname": localStorage.getItem("loggedInUser"),

            }} />
          </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    videoRecReq: (value) => {
      return dispatch(
        {
          type: 'videoRecReq',
          value: value
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Call));


// <div className='player-container row'>
// {joinState &&
//     <div className='local-player-wrapper col-md-6'>
//         <div className='local-player-text'>{localVideoTrack && `Host`}{joinState && localVideoTrack ? `(${ client.uid })` : ''}</div>
//         <div className="header" >
//             <span style={{ color: "#fff"}}>
//                 {/* <i className="fas fa-phone-alt"></i> */}
//                 <img src={CallIcon} alt="icon" height="22px"/>
//             </span>
//             <span className="headertext">Ongoing call</span>
//             <span className="fr" onClick={() => { screenshare(appId, channel, token, uid) }}>
//                 <i className="fas fa-share-square"></i>
//                 {/* <img src={ShareIcon} alt="sharescreen" /> */}
//             </span>
//         </div>
//         <div className="footer">
//             <span style={{ color: 'red',cursor:"pointer" }} onClick={() => { leave() }}>
//                 {/* <i className="fas fa-phone-slash"></i> */}
//                 <img src={CallCut} alt="end call" height="22px"/>
//                 </span>
//             <span className="fr" id="mute-video" onClick={() => mutevideo()}><i className="fas fa-video"></i></span>
//             <span className="fr" id="mute-audio" onClick={() => muteaudio()}><i className="fas fa-microphone"></i></span>
//         </div>
//         <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width="550px" height="420px" position="relative" local={true}></MediaPlayer>
//     </div>
// }
// <div className="col-md-6">
//     <div className="row">
//         {remoteUsers.map(user => (<div className='remote-player-wrapper col-md-6' key={user.uid}>
//             <div className='remote-player-text'>{`remoteVideo(${ user.uid })`}</div>
//             <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width="270px" height="200px"></MediaPlayer>
//         </div>))}
//     </div>