import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateDetail from '../dateDetail';
import TestNameDetail from '../testNameDetail';
import TestDetailCard from '../testDetailCard';
import { LabtestData } from '../../observationsJson';
import moment from 'moment';

var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function VitalSigns(props) {
  const [title, setTitle] = useState('')
  const [descData, setDescData] = useState({})
  let [progress, setProgress] = useState(false);
  const [data, setData] = useState([])
  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if (props.data?.patient360 === "Y") {
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?patientId=${props.data.ehrPatientId}&eventType=Observation&from=0&size=1000`
      } else if (props.data.patientId != "" || null || undefined) {
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?hekmaMail=${props.data.email}&eventType=Observation&from=0&size=1000`
      } else {
        url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?patientId=${props.data.subjectId}&eventType=Observation&from=0&size=1000`
      }

    } else {
      url = `https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patientdetail?hekmaMail=${props.data.email}&eventType=Observation_CMS&from=0&size=1000&patientId=${props.data.subjectId}`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          var initialdata = []
          var vitalsignsdata = []
          //finding only labdata
          responseData.data.map((item, i) => {
            if (props.app === "Patient" && item?.category[0]?.coding[0].code === "vital-signs" || props.app === "Site" && item?.category?.coding[0].code === "vital-signs") {
              // if(item?.code?.text==="Blood Pressure"){

              // }
              console.log(item?.code?.text==="Blood Pressure" ?(item?.component[0].valueQuantity?.value + '/'+ item?.component[1].valueQuantity?.value):item?.valueQuantity?.value != undefined ? (item?.valueQuantity?.value).toFixed(2):"")
              
              var obj = {
                "testTitle": item?.code?.text,
                "value": item?.code?.text==="Blood Pressure" ? (item?.component[0].valueQuantity?.value) : item?.valueQuantity?.value != undefined ? (item?.valueQuantity?.value).toFixed(2) : "",
                "ref": item?.code?.text==="Blood Pressure" ? item?.code?.text + ' ' + item?.component[0].valueQuantity?.unit : item?.code?.text + ' ' + item?.valueQuantity?.unit,
                "unit": item?.code?.text==="Blood Pressure" ? item?.component[0].valueQuantity?.unit : item?.valueQuantity?.unit,
                "date": item?.effectiveDateTime
              }
              if(item?.code?.text==="Blood Pressure"){
                obj["value1"]=item?.component[1].valueQuantity?.value
              }
              vitalsignsdata.push(obj)
            }
          })
          console.log(vitalsignsdata, "vitalsignsdata")
          let sortedArray = vitalsignsdata.sort((a, b) => new Date(a.date) - new Date(b.date))

          let formatArray = sortedArray.map((item, i) => { return { ...item, date: moment(item.date).format('Do MMM YYYY') } })
          const datawithOutDup = Array.from(new Set(formatArray.map(s => s.ref)))
            .map(labname => {
              return {
                ...formatArray.find((item) => (item.ref === labname) && item),
                ref: labname,
                value: formatArray.filter(s => s.ref === labname).map(edition => edition.value),
                value1: formatArray.filter(s => s.ref === labname).map(edition => edition.value1),
                date: formatArray.filter(s => s.ref === labname).map(edition => edition.date),
                min: labname==="Blood Pressure mm[Hg]" ? "80":"",
                max: labname==="Blood Pressure mm[Hg]" ? "120":""

              }
            })
          console.log(datawithOutDup, "datawithOutDup")

          /////////////////////////////////////////
          const getData = (linedata, min, max,type) => {
            console.log(linedata, min, max)
            var newdata = linedata.map((item, i) => {
              if ((max != "" && item > max) || (min != "" && item < min)) {
                var val = {
                  y: parseFloat(item),
                  color: '#FF0000'
                }
                return val
              } else return parseFloat(item)
            })
            return newdata
          }
          var finaldata = datawithOutDup.map(v => ({ ...v, data: getData(v.value, v.min, v.max),data1: getData(v.value1, v.min, v.max), desc: "", xaxis: v.date }))
          console.log(finaldata, "final")
          setData(finaldata)
        }
        else {
        }
      })

  }, [])


  const clickHandler = (e, index, title) => {
    e.preventDefault();
    setDescData(data[index])
    setTitle(title)
  }
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/labdata/add')
    }
  }
  console.log(data, "vitaldata")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ?
          <>
            <section>
              <MHSearchField
                title="Vital Signs"
                label="Search Lab Report"
                addBtnText="ADD VITAL SIGN"
                // addBtnText="Add Medication"
                app={props.app}
                handleChange={handleChange}
              />
            </section>
            <section className="labDetailPage">
              <section className="labTableRow">
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <section className="row">
                      {/* <div className="col-12 col-sm-4">
                        <span><b>Date</b></span>
                      </div> */}
                      <div className="col-12 col-sm-8">
                        <span><b>Test Name</b></span>
                      </div>
                    </section>
                  </div>
                  <div className="col-sm-6 col-md-8">
                    <span><b>Detail</b></span>
                  </div>
                </div>
              </section>
              <section className="">
                <div className="row">

                  <div className="col-sm-6 col-md-4 datedescription">
                    {data.map((item, i) => (
                      <section className="row mb-3">
                        {/* <div className="col-12 col-sm-4">
                          <DateDetail
                            year={new Date(item.date).getFullYear()}
                            mdate={month_names_short[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate()}
                            icon={'fas fa-flask'}
                            icolor='#863DD0'
                          />
                        </div> */}
                        <div className="col-12 col-md-12 col-sm-8">
                          <TestNameDetail
                            title={item.testTitle}
                            clickHandler={(e) => clickHandler(e, i, item.testTitle)}
                            color="#863DD0"
                          />
                        </div>
                      </section>
                    ))}
                  </div>

                  <div className="col-sm-6 col-md-8">
                    <TestDetailCard
                      // data={descData}
                      // title={title}
                      data={Object.keys(descData).length > 0 ? descData : data[0]}
                      title={data.length > 0 && title == "" ? data[0].testTitle : title}
                    />
                  </div>
                </div>
              </section>
            </section>
          </> : "No data found"
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}


export default withRouter(connect(mapStateToProps, null)(VitalSigns));



