import React, { useEffect, useState, useContext } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { oldgenericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { checkPermission } from '../../../common/utilis';

function Patient(props) {
    let isInvitePatient = checkPermission('S_Invite_Patients', 'Create');
    let isAddPatient = checkPermission('S_Patients_Create', 'Create');
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    async function fetchData(searchKey) {
        var newdata = []
        var bulkdata = []
        let requestBody = {
            query: `query {
          getStudySitePatientStatusSearch(studyId:"*",siteId:"${props.formValues.autosite.sponsorAccountId}",onboardStatus:"*",subjectStatus:"*",searchStr:"") {
            studyId
            siteId
            patientId
            subjectStatus
            onboardStatus
            subjectId
            alternateId
            firstName
            middleName
            lastName
            email
            phone
            address1
            address2
            city
            state
            zip
            country
          } 
        }`
        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await oldgenericApi('getStudySitePatientStatusSearch', requestBody);
        setProgress(false);
        if (!res) {
            // Error handling
            //console.log('Error in fetching patientsx!')

        } else {
            if (res.data.getStudySitePatientStatusSearch.length) {
                newdata = res.data.getStudySitePatientStatusSearch
            }
        }
        setProgress(true);
        await fetch(appConfig.BaseUrl + 'patient?size=200&from=0',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed!');
                }
                return res.json();
            })
            .then(responseData => {
                if (responseData?.data.length > 0) {
                    responseData.data.map((item, i) => {
                        var obj = {
                            "studyId": "",
                            "siteId": "",
                            "patientId": "",
                            "subjectStatus": "",
                            "onboardStatus": "",
                            "subjectId": item.id,
                            "alternateId": "",
                            "gender": item.gender,
                            "birthdate": moment(item.birthDate).format('YYYY-MM-DD'),
                            "firstName": item.name[0].given[0],
                            "middleName": "",
                            "lastName": item.name[0].family[0],
                            "email": "",
                            "phone": item.telecom[0].value,
                            "address1": item.address[0].line[0],
                            "address2": "",
                            "city": item.address[0].city,
                            "state": item.address[0].state,
                            "zip": item.address[0].postalCode,
                            "country": item.address[0].country,
                            "language": item.communication[0].language.coding[0].display,
                        }
                        bulkdata.push(obj)
                    })

                }
            })
        var data = [...bulkdata, ...newdata]
        //console.log(bulkdata, newdata, data)
        setData(data)
        setProgress(false);
    }
    async function searchfunc(searchstr) {
        let bulkdata = []
        let newdata = []
        let requestBody = {
            query: `query {
          getStudySitePatientStatusSearch(studyId: "*", siteId: "${props.formValues.autosite.sponsorAccountId}",onboardStatus:"*",subjectStatus:"*",searchStr:"${searchstr}") {
                    studyId
                    siteId
                    patientId
                    subjectStatus
                    onboardStatus
                    subjectId
                    alternateId
                    firstName
                    middleName
                    lastName
                    email
                    phone
                    address1
                    address2
                    city
                    state
                    zip
                    country
                    language
            }
        }`
        };
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await oldgenericApi('getStudySitePatientStatusSearch', requestBody);
        if (!res) {
            // Error handling
            //console.log('Error on getting patient data!')

        } else {
            if (res.data.getStudySitePatientStatusSearch.length) {
                newdata = res.data.getStudySitePatientStatusSearch
            } else {
                newdata = []
            }
        }
        await fetch(appConfig.BaseUrl + `patient?size=200&from=0&text_search=${searchstr}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed!');
                }
                return res.json();
            })
            .then(responseData => {
                if (responseData.data.length > 0) {
                    responseData.data.map((item, i) => {
                        var obj = {
                            "studyId": "",
                            "siteId": "",
                            "patientId": "",
                            "subjectStatus": "",
                            "onboardStatus": "",
                            "subjectId": item.id,
                            "alternateId": "",
                            "gender": item.gender,
                            "birthdate": moment(item.birthDate).format('YYYY-MM-DD'),
                            "firstName": item.name[0].given[0],
                            "middleName": "",
                            "lastName": item.name[0].family[0],
                            "email": "",
                            "phone": item.telecom[0].value,
                            "address1": item.address[0].line[0],
                            "address2": "",
                            "city": item.address[0].city,
                            "state": item.address[0].state,
                            "zip": item.address[0].postalCode,
                            "country": item.address[0].country,
                            "language": item.communication[0].language.coding[0].display,
                        }
                        bulkdata.push(obj)
                    })

                }
            })
        console.log(bulkdata, newdata, "new")
        var data = [...bulkdata, ...newdata]
        //console.log(bulkdata, newdata, data)
        setData(data)
        setProgress(false);
    }

    useEffect(() => {
        async function getApiData() {
            fetchData(props.searchKey)
            //let apiData = await fetchData(props.searchKey);
            //setData(apiData)
            //props.clearForm()
        }
        getApiData();
        props.UpdateFormField({ name: "hidestudytab", value: false })
        //to clear form
        props.UpdateFormField({ name: "addpatientfromAI", value: false })
        return () => {
            props.UpdateFormField({ name: "hidestudytab", value: true })
        }
    }, []);



    const handlePageClick = (offset) => {
        setoffset(offset)
    }

    const handleClick = async (event) => {
        event.preventDefault();
        if (localStorage.getItem('whichApp') === "Site") {
            props.history.push('/site/addPatient');
        } else {
            props.history.push('/app/addPatient');
        }

    }

    const handleRowClick = async (event, rowData) => {
        // console.log(rowData, '-------patient')

        let dataToR_Items = {

            id: rowData.subjectId,
            name: rowData.firstName + ' ' + rowData.lastName,
            ...rowData
            //accountType: rowData.accountType
        }

        setProgress(true);
        let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_patients);
        if (response) {
            let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_patients);
            if (actItems) {
                let obj = {
                    obj: R_Items.rI_patients,
                    value: actItems
                }
                props.updateRecentItems(obj);
            }
        }
        setProgress(false);
        props.UpdateFormField({ name: "patientdata", value: rowData })
        // await props.UpdateEntireForm(rowData);
        if (localStorage.getItem('whichApp') === "Site") {
            props.history.push('/site/patient');
        } else {
            props.history.push('/app/patient');
        }

    }

    const searchHandle = (event) => {
        event.preventDefault();
        let value = event.target.value;
        setValSearch(value);
    }
    const searchSubmit = (event) => {
        event.preventDefault();
        if (valSearch.length) {
            searchfunc(valSearch);
            setoffset(0)
        } else {
            fetchData();
        }
    }
    const clearSearch = (event) => {
        event.preventDefault()
        fetchData();
        setValSearch("")
    }
    const handleDelete = (event, data) => {
        ////console.log('delete click 1')
        event.preventDefault();
    }

    const Columns = [
        // {
        //     title: 'Patient ID', field: 'subjectId',
        //     cellStyle: {
        //         color: '#244271',
        //         fontWeight: 500,
        //         width: '20%'
        //     },
        // },
        {
            title: 'Name', field: 'firstName',
            cellStyle: {
                color: '#244271',
                fontWeight: 500,
                textAlign: 'left'
            },
            render: data => data.firstName + ' ' + data.lastName
        },
        // {
        //     title: 'onboardStatus', field: 'onboardStatus'
        // },
        {
            title: "Email", field: 'email',
            // cellStyle: {
            //     color: '#244271',
            //     fontWeight: 500,
            //     textAlign: 'left'
            // },
        },
        { title: 'Phone', field: 'phone' },
        { title: 'City', field: 'city' },
        { title: 'Country', field: 'country' },
    ]

    const inviteClick = (e) => {
        e.preventDefault()
        alert('under progress')
    }
    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}



            <div className="d-flex mb-3 justify-content-end">
                <h1 className="mr-auto primary-header">Patients</h1>
                <div className="">
                    {/* {isInvitePatient === "1" ?
                        <button onClick={inviteClick} className="btn btn-primary-blue btn-rounded mr-2">
                            <i className="fa fa-plus-circle mr-1"></i>ADD PATIENT BY EMAIL</button> : ""} */}
                    {localStorage.getItem('whichApp') === "Site" && isAddPatient === "1" ?
                        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                            <i className="fa fa-plus-circle mr-1"></i>ADD PATIENT</button> : ""}
                    {localStorage.getItem('whichApp') != "Site" ?
                        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                            <i className="fa fa-plus-circle mr-1"></i>ADD PATIENT</button> : ""}
                </div>
            </div>
            <div className="listing-wrap hekmaGrid">
                <div className="card mb-0">
                    <div className="hekmaGridBox">
                        <div className="col-xs-12 col-sm-12 col-md-5">
                            <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                                <HekmaTextSearchField
                                    name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                                    // label="Search Account"
                                    placeholder="Search Patients"
                                    value={valSearch}
                                    handleChange={searchHandle}
                                    className="gridSearchField"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                                                {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),

                                        endAdornment: (
                                            <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                                                X
                                                {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </form>
                        </div>
                        {/* {data.length > 5 && */}
                        <Materialtable
                            columns={Columns}
                            data={data}
                            handleRowClick={handleRowClick}
                            actions={true}
                        />

                        {/* } */}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateRecentItems: (obj) => {
            return dispatch(
                {
                    type: 'updateRecentItems',
                    value: obj,
                }
            )
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Patient));


