import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import { ViewRow } from '../../../../globalcomponents/formElements';
import PatientAI from '../patient';
import { Paginationn } from '../../../../common/pagination';
import { appConfig } from "../../../../common/constants";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { genericApi } from '../../../../common/apiconfig';
import Move_Icon from '../../../../img/move_to.svg'
import { connect, useSelector, useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const PatientDetail = (props) => {
  var patientdata = [{ "id": "1beea36c34e1", "score": "0.7407407407407408", "birthDate": 95, "address_city": "Amherst", "state": "Massachusetts", "country": "US", "postal_code": 1002, "com_language": "Italian", "gender": "female", "maritalStatus": "M", "race": "White" }, { "id": "8db5376184ec", "score": "0.7407407407407408", "birthDate": 58, "address_city": "Melrose", "state": "Massachusetts", "country": "US", "postal_code": 2176, "com_language": "English", "gender": "female", "maritalStatus": "M", "race": "White" }, { "id": "4aa3e9934992", "score": "0.7407407407407408", "birthDate": 48, "address_city": "Palmer Town", "state": "Massachusetts", "country": "US", "postal_code": 1009, "com_language": "Russian (Russia)", "gender": "female", "maritalStatus": "S", "race": "White" }, { "id": "fec222143b50", "score": "0.7407407407407408", "birthDate": 64, "address_city": "Newton", "state": "Massachusetts", "country": "US", "postal_code": 2458, "com_language": "English", "gender": "female", "maritalStatus": "M", "race": "White" }, { "id": "7d4b7eaae4d5", "score": "0.7407407407407408", "birthDate": 83, "address_city": "Scituate", "state": "Massachusetts", "country": "US", "postal_code": 2066, "com_language": "English", "gender": "female", "maritalStatus": "M", "race": "White" }, { "id": "6b118373ae84", "score": "0.7407407407407408", "birthDate": 79, "address_city": "Boston", "state": "Massachusetts", "country": "US", "postal_code": 2108, "com_language": "Spanish", "gender": "female", "maritalStatus": "S", "race": "Other" }, { "id": "76e8c169181e", "score": "0.7407407407407408", "birthDate": 62, "address_city": "Worcester", "state": "Massachusetts", "country": "US", "postal_code": 1545, "com_language": "English", "gender": "female", "maritalStatus": "M", "race": "White" }]

  const [offset, setoffset] = useState(0)
  const [data, setData] = useState(props.formValues.AIPatientsData || [])
  //props.formValues.AIPatientsData
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [studyData, setStudyData] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reduxdispatch = useDispatch();

  useEffect(() => {
    let pirobj = {
      search: true,
      result: true,
      patient: true
    }
    reduxdispatch({ type: 'pirmenu', value: pirobj })
  }, []);
  // useEffect(() => {
  //   props.history.push('/site/study/pir/searchcriteria')
  //   }, [props.formValues.autostudy.studyId])

  const handleRowClick = (event, rowData) => {
    //console.log(rowData, "rowData")
    var ids = ["7eca53ae7b08", "43dc4fbf4167", "c99f01499b84"]
    callApi(ids[Math.floor(Math.random() * ids.length)], "dashboard")
  }
  const callApi = async (id, token) => {
    setProgress(true)
    await fetch(`https://9vhro6n22f.execute-api.us-west-1.amazonaws.com/HekmaApiPool/patient?size=200&from=0&ids=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        if (responseData.data.length > 0) {
          setProgress(false)
          //console.log(responseData.data, "data")
          var data
          responseData.data.map((item, i) => {
            data = {
              "studyId": "",
              "siteId": "",
              "patientId": "",
              "subjectStatus": "",
              "onboardStatus": "",
              "subjectId": item.id,
              "alternateId": "",
              "gender": item.gender,
              "birthdate": moment(item.birthDate).format('YYYY-MM-DD'),
              "firstName": item.name[0].given[0],
              "middleName": "",
              "lastName": item.name[0].family[0],
              "email": "",
              "phone": item.telecom[0].value,
              "address1": item.address[0].line[0],
              "address2": "",
              "city": item.address[0].city,
              "state": item.address[0].state,
              "zip": item.address[0].postalCode,
              "country": item.address[0].country,
              "language": item.communication[0].language.coding[0].display,
            }

          })
          //console.log(data, "data1")
          props.UpdateFormField({ name: "patientdata", value: data })
          if (token === "dashboard") {
            props.history.push('/site/patient');
            props.UpdateFormField({ name: "pirpatienttoken", value: true })
          }
          if (token === "addpatient") {
            callPatientApi(data)
          }
        } else {
          setProgress(false)
        }
      })
  }

  const callPatientApi = async (data) => {
    setProgress(true);
    let requestBody = `
    mutation {
      addSitePagPatient(sitePagId:"${props.formValues.autosite.sponsorAccountId}",subjectId:"",alternateId:"${data.subjectId}",firstName:"${data.firstName}",middleName:"",lastName:"${data.lastName}", address1:"${data.address1}",address2:"",countryCode:"US",city:"${data.city}",state:"",zip:"${data.zip}",email:"",phone:"${data.phone}",language:"English(US)"){
        code
        type
        message
        attributes
      }}`;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await genericApi('addSitePagPatient', requestBody);
    // setProgress(false);

    if (res && res.data.addSitePagPatient.code === "200") {
      //console.log(res.data.addSitePagPatient, "add")
      var data = res.data.addSitePagPatient.attributes
      var id = data.split(":")[1].split("}")[0];
      if (id != "") {
        callPrescreeningAPi(id)
      }
    }
  }


  const callPrescreeningAPi = async (id) => {
    let contactsSelected = '';
    //here to add all selected row from redux
    contactsSelected = `{ studyId: "${props.formValues.autostudy.studyId}",siteId: "${props.formValues.autosite.sponsorAccountId}", patientId: "${id}" }`;
    let contactss = contactsSelected.replace(/}\s*{/g, '},{');
    //console.log(contactss)
    let requestBody = {
      query: `mutation {
        addStudySiteMultiPatient(studySitePatients: [${contactss}]) {
          code
          type
          message
          attributes
        }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await genericApi('addStudySiteMultiPatient', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error on adding patients!')

    } else {
      //console.log('added to prescreening')
      props.history.push('/site/study/subject/prescreening')

      // if (res.data.addStudySiteMultiPatient.length) {
      //   setData(res.data.addStudySiteMultiPatient)
      // }
    }
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }
  const onboardPatient = async (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log(props.formValues, "onboard")
    if (props.formValues.autostudy != "") {
      callApi(data.id, "addpatient")
    } else {
      alert('please select study')
    }
  }
  let mColumns = [
    {
      title: 'ID', field: 'id', cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    // {
    //   title: 'Score', field: 'score', cellStyle: {
    //     color: '#244271',
    //     fontWeight: 500,
    //     width: '20%'
    //   },
    //   render: data => parseFloat(data.score).toFixed(2)
    // },
    { title: 'DOB', field: "birthDate" },
    { title: 'Gender', field: 'gender' },
    // { title: 'Race', field: 'race' },
    // { title: 'City', field: 'address_city' },
    // { title: 'State', field: 'state' },
    {
      title: 'Study', field: 'study', cellStyle: { textAlign: "left" },
      render: data => <div onClick={(e) => { handleClick(e, data.id) }}><i className="far fa-eye" aria-controls="simple-menu" aria-haspopup="true" style={{ color: "#203E69" }} />
        {!progress &&
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {
              studyData.length > 0 ?
                studyData.map((item, i) => {
                  return <MenuItem onClick={handleClose}>{item.studyTitle}</MenuItem>
                })
                : <span>No Studies Found</span>
            }
          </Menu>}</div>
    },
  ]

  let sColumns = [
    {
      title: 'ID', field: 'id', cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // width: '60%'
      },
    },
    { title: 'DOB', field: "birthDate" },
    { title: 'Gender', field: 'gender' },
    { title: "FamilyName", field: 'familyName' },
    { title: 'GivenName', field: 'givenName' },
    { title: 'Address', field: 'addressText' },
    { title: 'MaritalStatus', field: 'maritalStatus' },
    { title: 'IdentifierValue', field: 'identifierValue' }
  ]
  const handleClick = async (event, pid) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setProgress(true)
    let requestBody = {
      query: `query {
          getStudyOfPatientIntegrationId(integrationId: "${pid}") {
            studyId
            studyNumber
            parentAccountId
            studyTitle
            nameofIMP
            }
        }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudyOfPatientIntegrationId', requestBody);
    setProgress(false)
    if (!res) {
      // Error handling
      //console.log('Error on getting study data!')
    } else {
      if (res.data.getStudyOfPatientIntegrationId.length) {
        setStudyData(res.data.getStudyOfPatientIntegrationId)
      } else {
        setStudyData([])
      }
    }
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
    setStudyData([])
  };
  const savePatientCount = () => {
    props.UpdateFormField({name:"patientscount",value:data.length})
    props.history.push('/site/pricingInfo')
  }
  return (
    <>

      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {/* <Title title="Patients List" /> */}
      <div className="listing-wrap hekmaGrid">
        {/* <section className="problemDetailTable"> */}
        <div className="card">

          <div className="hekmaGridBox">
            <MaterialTable
              style={{ boxShadow: "none" }}
              columns={sColumns}
              data={data != (undefined || null) ? data : []}
              // data={data.length > 0 ? data.slice(offset, 5 + offset) : []}
              title="Patients List"
              // actions={[
              //   rowData => ({
              //     icon: 'add',
              //     tooltip: 'Onboard Patient',
              //     onClick: (event, rowData) => Window.confirm("You want to delete "),
              //   })
              // ]}
              onRowClick={(event, rowData) => {
                event.preventDefault();
                handleRowClick(event, rowData);
              }}

              options={{
                actionsColumnIndex: -1,
                searchFieldAlignment: 'left',
                showTitle: true,
                paging: data.length > 5 ? true : false,
                sorting: data.length > 2 ? true : false,
                headerStyle: {
                  textAlign: 'left',
                  flexDirection: 'row'
                },
                cellStyle: {
                  textAlign: 'left',
                  flexDirection: 'row'
                },
                padding: 'dense',
                draggable: false,
                search: false,
              }}

            // localization={{
            //   header: {
            //     actions: 'Actions'
            //   }
            // }}
            // components={{
            // Action: props => (
            //     <div
            //       className="fullwidth text-center delIconColor"
            //       onClick={(event) => onboardPatient(event, props.data)}
            //     >
            //       <Tooltip title="Onboard Patient" arrow>   <img src={Move_Icon} alt="Move_to" /></Tooltip>
            //     </div>
            // ),
            //   }}
            />
            {/* {data.length > appConfig.paginationLimit &&
              <Paginationn
                handlePageClick={handlePageClick}
                data={data.length}
                offset={offset}
              />
            } */}
          </div>
        </div>
        {
          props.formValues.nonregisterstudies && data.length>0 && <button className="btn btn-primary-blue btn-rounded btn-small mr-1 fr mt-3" onClick={savePatientCount}>Save</button>
        }
      </div>
      {/* </section>  */}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDetail));