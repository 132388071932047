import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { oldgenericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Materialtable from '../../../globalcomponents/materialtable'
import Loader from '../../../common/loader'

function SiteGrid(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    console.log(rowData,"rowData")
    props.UpdateFormField({name:"hospitalData",value:rowData})
    props.UpdateFormField({name:"hospitalName",value:rowData.accountName})
    props.UpdateFormField({name:"hospitalId",value:rowData.sponsorAccountId})
    props.setShowHospitals(false)
  }

  useEffect(() => {
    async function fetchData() {
      let requestBody = {
        query: `query {
        getAccountOfTypeSearch(accountType:"Site") {
          sponsorAccountId
          accountName
          parentAccountId
          accountType
          status
          address1
          address2
          city
          state
          country
          zip
          email
          phone
          
        }
        }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await oldgenericApi('getAccountOfTypeSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountOfTypeSearch.length) {
          var data = res.data.getAccountOfTypeSearch
          setData(data)
        }
      }
    }
    fetchData()
    fetch('https://clinicaltrials.gov/api/int/studies?cond=Heart Attack and Stroke&titles=heart').then(response => response.json())
    .then((data) => {
      console.log(data,"apicall")
    })
  }, []);

  const Columns = [
    {
      title: 'Name', field: 'accountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'Type', field: 'accountType'
    },

    { title: "Parent ID", field: 'parentAccountId' },
    { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
    // {title:"Status", field:'status'}
  ]
  return (
    <>
      <Loader progress={progress}/>
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Hospital List</h1>
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
           
            <Materialtable
              columns={Columns}
              data={data}
              search={true}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteGrid));


