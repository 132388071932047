import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavMenu } from '../../siteSrc/components/navMenu';
import Registered from '../../siteSrc/components/site/accounts/registeredstudies'
import Nonregistered from '../../siteSrc/components/site/accounts/nonRegisteredstudies'

const appmenus = [
  {
    to: "/app/trailmatch/registeredstudies",
    text: "Registered Studies"
  },
  {
    to: "/app/trailmatch/nonRegisteredstudies",
    text: "Non-Registered Studies"
  }
]

function Trailmatch(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  // useEffect(() => {
  //   getApiData();
  // }, []);
  useEffect(() => {
    // getApiData()
  }, [])


  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex">
        {/* <div class="secondary-header mr-auto">Subjects</div> */}
      </div>
      <section className="mb-3">
        <NavMenu menus={appmenus} />
      </section>

      <div className="">
        <Switch>
        
              <Route exact path="/app/trailmatch/registeredstudies">
                <Registered />
              </Route>
              {/* <Route path="/app/trailmatch/registeredstudies">
                <Registered />
              </Route> */}
              <Route path="/app/trailmatch/nonRegisteredstudies">
                <Nonregistered />
              </Route>

              <Redirect to="/app/trailmatch/registeredstudies" />
    
        </Switch>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trailmatch);

