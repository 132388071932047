import React, { useEffect, useState } from "react";
import { appConfig } from '../../common/constants'
import PIRTextaria from './PIRTextaria';
import CircularProgress from '@material-ui/core/CircularProgress';
import { genericApi } from '../../common/apiconfig';
import { connect, useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import InclusionIcon from '../../img/Inclusion.svg'
import ExclusionIcon from '../../img/Exclusion.svg'
import AddCriteriaModal from './addcriteriamodal';
import * as jq from 'jquery';
import { withRouter } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SimpleModal from '../simplemodal'
import Loader from "../../common/loader";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //     backgroundColor: theme.palette.common.black,
    //     color: theme.palette.common.white,
    // },
    // [`&.${tableCellClasses.body}`]: {
    //     fontSize: 14,
    // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(nctid, patienid) {
    return { nctid, patienid };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];
const initialForm = {
    inclusion: [],
    exclusion: []
}
let responseData = '';

function SearchCriteria(props) {
    console.log(props, "props")
    let [form, setForm] = useState(initialForm);
    const [entityData, setEntityData] = useState(null);
    const [progress, setProgress] = useState(false)
    let [showentity, setshowentity] = useState(false);
    let [isAddCriteria, setAddCriteria] = useState(false);
    let [criteriaType, setCriteriaType] = useState('inclusion');
    let [criteriaValue, setcriteriaValue] = useState('');
    let [isEdit, setEdit] = useState(false);
    let [arrayInx, setarrayInx] = useState();
    let [showall, setshowall] = useState(false);
    let [data, setData] = useState([])

    let [sitePopup, setSitePopup] = useState(false);

    const updateShowAll = (flag) => {
        submitHandler('', flag);
    }

    const reduxdispatch = useDispatch();


    let reduxstateobj = useSelector(state => state);

    useEffect(() => {
        let pirobj = {
            search: true,
            result: false,
            patient: false
        }
        reduxdispatch({ type: 'pirmenu', value: pirobj })

        //verifyopenApi();
    }, []);

    const verifyopenApi = () => {
        async function postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                'Access-Control-Allow-Origin': '*',

                headers: {
                    //'Content-Type': 'application/json'
                    'Content-Type': 'application/x-www-form-urlencoded',
                    mode: 'no-cors',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        }

        postData('http://www.ohdsi.org/web/criteria2query/ie/getct', { nctid: 'NCT04924309' })
            .then(data => {
                console.log(data); // JSON data parsed by `data.json()` call
            });
    }

    async function getApiData() {
        setshowentity(false);
        setshowall(false);
        if (props.sponsor) {
            setForm({
                ...form,
                'inclusion': props.Inclusion,
                'exclusion': props.Exclusion
            })
            if (props.sponsor && props.Pir != undefined && props.Pir != "") {
                collapseLeftPanel();
                setProgress(false)
                setshowentity(true)
                responseData = props.Pir;
                formateResponseData(props.Pir);

            }
            function formateResponseData(data) {
                setEntityData(data);
            }
        } else {
            let eventType = "I"
            let requestBody = {
                query: `query {
                  getStudyEvent(studyId: "${props.formValues.autostudy.studyId}", eventType: "B") {
                    studyId
                    eventId
                    eventType
                    type
                    description
                    effectiveDate
                    status
              }
          }`
            };
            requestBody = JSON.stringify(requestBody);
            setProgress(true);
            let res = await genericApi('getStudyEvent', requestBody);
            setProgress(false);
            if (!res) {
                // Error handling
                //console.log('Error in study details!')

            } else {
                if (res.data.getStudyEvent.length) {
                    buildIEdata(res.data.getStudyEvent);
                } else {
                    setForm({
                        ...form,
                        'inclusion': [],
                        'exclusion': []
                    })
                }
            }
        }
    }
    useEffect(() => {
        getApiData()
    }, [props.formValues.autostudy.studyId])

    function buildIEdata(iedata) {
        let EData = [];
        let IData = [];
        iedata.map((item, inx) => {
            if (item.eventType == 'E' && item.status == 'Active') {
                EData.push(item.description)
            } else if (item.eventType == 'I' && item.status == 'Active') {
                IData.push(item.description)
            } else {
                return ''
            }
        })

        setForm({
            ...form,
            'inclusion': IData,
            'exclusion': EData
        })

    }

    const formatIEData = (input) => {
        if (input.length) {
            let modifiedArray = input.map((item, inx) => {
                if (item.charAt(item.length - 1) == '.') {
                    return item.concat(' \n');
                } else {
                    return item.concat('. \n');
                }
            })
            return modifiedArray;
        } else {
            return [];
        }
    }

    const deleteHandler = (e, inx, type) => {
        e.preventDefault();

        // //console.log('delete inx', inx);
        // //console.log('delete type', type);


        let oldType = form[type];
        oldType.splice(inx, 1);
        setForm({
            ...form,
            [type]: oldType
        })
        //closeAddCriteria();

        //dialogclose(false);
        //setEntitydata({ ...entityData, ...oldObj });
    }

    const edit = (e, inx, type, value) => {
        e.preventDefault();
        try {
            //setarrayInx(inx);
            //setAddCriteria(true);
            //setCriteriaType(type);
            //setEdit(true)
            // setcriteriaValue(form[type][inx]);



            let newArr = form[type];
            newArr[inx] = value;
            setForm({
                ...form,
                [type]: newArr
            })

        } catch (error) {

        }


        // setForm({
        //     ...form,
        //     [e.target.name]: e.target.value
        // })
    }

    const addCriteria = (e, type) => {
        e.preventDefault();
        setAddCriteria(true);
        setEdit(false);
        setcriteriaValue('');
        if (type == "inclusion") {
            setCriteriaType('inclusion');
        } else {
            setCriteriaType('exclusion');
        }

    }

    const closeAddCriteria = () => {
        setAddCriteria(false);
        setEdit(false);
    }

    const submitAddCriteria = (e, value, type, isEdit, arrInx) => {
        e.preventDefault();
        if (value.length >= 1) {
            if (isEdit) {
                let newArr = form[type];
                newArr[arrInx] = value;
                setForm({
                    ...form,
                    [type]: newArr
                })
            } else {
                setForm({
                    ...form,
                    [type]: [...form[type], value]
                })
            }
            setAddCriteria(false);
        }
    }

    const submitHandler = (e, showallFlag) => {

        // console.log("array stirn::", form);

        // let inclusionData = formatIEData(form.inclusion).join('');
        // let exclusionData = formatIEData(form.exclusion).join('');
        // let reqParam = {};
        // reqParam.inc = inclusionData;
        // reqParam.exc = exclusionData;
        if (form.inclusion.length == 0 && form.exclusion.length == 0) {
            alert('Please Enter anyone of the IE criteria')

        } else {

            props.history.push(props.nextPageUrl)

        }
    }

    const expandLeftPanel = () => {
        try {
            jq("#pirleftpanel").animate({ width: "30%" }, 300, () => {
                jq("#pirleftcontent").animate({ width: "100%" }, 300, () => {
                    jq("#pirleftpanel").addClass("col-12 col-sm-4");
                    jq("#pirrightpanel").addClass('col-sm-8');
                    jq("#addBtnRightPanel").hide();

                    jq("#expandClmn").removeClass("showexpand");
                    jq("#pirleftpanel").show();
                })
            });
        } catch (error) {

        }
    }

    const collapseLeftPanel = () => {
        try {
            jq("#pirleftpanel").animate({ width: "0px" }, 300, () => {
                jq("#pirleftcontent").animate({ width: "0px" }, 300, () => {
                    jq("#pirleftpanel").removeClass("col-12 col-sm-4");
                    jq("#pirrightpanel").removeClass('col-sm-8');
                    jq("#addBtnRightPanel").show();

                    jq("#expandClmn").addClass("showexpand");
                    jq("#pirleftpanel").hide();
                })
            });
        } catch (error) {

        }
    }
    const showPatientsMatched = (e) => {
        e.preventDefault();
        props.history.push('/account/pir/results')
    }

    const openSiteSelectionPoup = () => {
        // e.preventDefault();
        // alert('open')
        setSitePopup(true)
    }
    const onClose = () => {
        setSitePopup(false)
    }

    const checkLineBreak = data => {
        var match = /\r|\n/.exec(data);
        if (match) { return true } else { return false }
    }

    const storeIEcriteria = async (inc, exc) => {
        var accountId = ""
        if (window.location.hostname === appConfig.host1) {
            accountId = "600298"
        } else if (window.location.hostname === appConfig.host2) {
            accountId = "600359"
        } else {
            accountId = "600298"
        }
        let testinc = await checkLineBreak(inc)
        let testexc = await checkLineBreak(exc)
        var incl = inc
        var excl = exc
        if (testinc) {
            var incc = inc[0].replace(/\n/g, '')
            incl = []
            incl.push(incc)
        }
        if (testexc) {
            var excc = exc[0].replace(/\n/g, '')
            excl = []
            excl.push(excc)
        }

        var resdata = JSON.stringify(responseData).replace(/\\/g, "\\\\").replace(/"/g, '\\"')
        let requestBody = `
        mutation {
            createOpenSquareNonrStudy(accountId:"${accountId}",inclusion:"${(incl.join(' || '))}",exclusion:"${(excl.join(' || '))}",reference:${JSON.stringify(resdata)}) {
             code
              type
              message
              attributes
            }
          }
      `;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await genericApi('createOpenSquareNonrStudy', requestBody, form);
        setProgress(false);

        if (res && res.data.createOpenSquareNonrStudy.code === '200') {
            props.history.push('/account/pir/siteselection')
            localStorage.setItem("nonRegStudyId", res.data.createOpenSquareNonrStudy.attributes)
        }
    }
    const callPatinetData = (e, patientid) => {
        e.preventDefault()
       
        setProgress(true)
        setData([])
        fetch(process.env.REACT_APP_NEWPIR + 'patient_summary/', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                // number: 'NCT00391196',
                // threshold: 'high'
                "patient_id": patientid
            })
        })
            .then(response => response.json())
            .then(response => { 
                console.log(response,"2nd api");   
                let newdata=[]
                setProgress(false); 
                newdata.push(response) 
                setData(newdata)
                setSitePopup(true); 
            })
            .catch(error =>{  setProgress(false); console.error(error)});
    }
    console.log(data,"data")
    return (
        <>
            <Loader progress={progress} />

            <div className="dashboardContainer mb-3">
                <section className="row searchcriteriacontainer align-items-stretch">
                    <SimpleModal header="Patient Info" open={sitePopup} onClose={onClose} width={"100%"}>
                        <div className="col-md-12">
                            {data.length>0 && <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>

                                            <StyledTableCell>Patient ID</StyledTableCell>
                                            <StyledTableCell style={{textAlign:"justify"}}>Patient Data</StyledTableCell>
                                            <StyledTableCell style={{textAlign:"justify"}}>Patient Summary</StyledTableCell>
                                            <StyledTableCell style={{textAlign:"justify"}}>Overall Conclusion</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow >
                                            <StyledTableCell >
                                                {data[0].patient_id}
                                            </StyledTableCell>
                                            <StyledTableCell >{JSON.stringify(data[0].patient_data)}</StyledTableCell>
                                            <StyledTableCell>{data[0].patient_summary}</StyledTableCell>
                                            <StyledTableCell>{data[0].overall_conclusion}</StyledTableCell>

                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>}
                        </div>
                    </SimpleModal>

                    <section className="col-12 col-sm-6" id="pirleftpanel">


                        <div className="pircontent" id="pirleftcontent">
                            <section className="d-flex justify-content-between">
                                <div className="iconPlace">
                                    <img src={InclusionIcon} />&nbsp;
                                    <div className="pirtitle">{"Inclusion Criteria"}</div>
                                </div>
                                {/* <div className="text-right">
                                    <button className="border-0 bg-transparent" onClick={(e) => addCriteria(e, 'inclusion')}>
                                        <i className="fas fa-plus-circle"></i>
                                    </button>
                                </div> */}
                            </section>

                            <section className="searchCriteriaPanel mt-3">
                                <ul>
                                    {console.log(form['inclusion'])}
                                    <pre>{form['inclusion']}</pre>
                                </ul>
                                {/* <PIRTextaria
                                    textvalue={form['inclusion']}
                                    name="inclusion"
                                    clickHandler={edit}
                                    hideDelete={false}
                                    deleteHandler={deleteHandler}
                                /> */}

                            </section>


                            <section className="d-flex justify-content-between mt-4">
                                <div className="iconPlace">
                                    <img src={ExclusionIcon} />&nbsp;
                                    <div className="pirtitle">{"Exclusion Criteria"}</div>
                                </div>
                                {/* <div className="text-right">
                                    <button className="border-0 bg-transparent" onClick={(e) => addCriteria(e, 'exclusion')}>
                                        <i className="fas fa-plus-circle"></i>
                                    </button>
                                </div> */}
                            </section>

                            <section className="searchCriteriaPanel mt-3">
                                {/* <PIRTextaria
                                    textvalue={form['exclusion']}
                                    name="exclusion"
                                    hideDelete={false}
                                    clickHandler={edit}
                                    deleteHandler={deleteHandler}
                                /> */}
                                <ul>

                                    <pre>{form['exclusion']}</pre>
                                </ul>
                            </section>
                            {/* <div className="mt-3 text-right">
                                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={submitHandler}>Submit</button>
                            </div> */}
                        </div>
                    </section>

                    <section className="middle-clmn-to-expand" id="expandClmn">
                        <div className="expandleft-collapse">
                            <i className="far fa-file-alt"></i>
                            <p className="mt-1">Criteria</p>
                        </div>

                        <div className="ex-collapse to-right" id="expand-collapse" onClick={() => expandLeftPanel()} style={{ backgroundColor: "#234170" }}>
                            <i className="fas fa-chevron-right" style={{ color: "white" }}></i>
                        </div>
                    </section>
                    <section className="col-12 col-sm-6">
                        <section className="pircontent">
                            <h5>Patients Matched</h5>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>NCT ID</StyledTableCell>
                                            <StyledTableCell>Patient ID</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(props.nctids).map((key, index) => (
                                            <TableRow key={index}>
                                                <StyledTableCell >
                                                    {props.nctids[key]}
                                                </StyledTableCell>
                                                <StyledTableCell ><a href="#" onClick={(e) => callPatinetData(e, Object.values(props.patientids)[index])}>{Object.values(props.patientids)[index]}</a></StyledTableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </section>
                    </section>


                    <section className="">
                        <AddCriteriaModal
                            open={isAddCriteria}
                            close={closeAddCriteria}
                            submit={submitAddCriteria}
                            title={criteriaType}
                            value={criteriaValue}
                            isEdit={isEdit}
                            inx={arrayInx}
                            deleteHandler={deleteHandler}
                        />
                    </section>
                </section>

            </div>
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchCriteria));



