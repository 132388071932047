import React, { useEffect, useState, useReducer, useContext } from "react";
import Loadable from 'react-loadable';
//import { useReactRouter } from 'react-router-dom';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AutoOptionsNew } from '../globalcomponents/formElements';
import { genericApi, securityApi, oldgenericApi } from '../common/apiconfig';
import { appConfig } from '../common/constants'
import { withRouter } from 'react-router-dom';
import Header from './header/header';
import { connect } from 'react-redux';
import L1 from '../img/l1.png'
import L2 from '../img/l2.png'
import L3 from '../img/l3.png'
import L4 from '../img/l4.png'
import L5 from '../img/l5.png'
import HospitalIcon from '../img/hospitalSign.svg'
import { checkPermission } from '../common/utilis';

const ImgArr = [L1, L2, L3, L4, L5]

const ChangeSite = (propsobj) => {
  let [sites, setSites] = useState([]);
  let [sponsors, setSponsors] = useState([]);
  let [progress, setProgress] = useState(false);
  let [imgSelected, setImgSelected] = useState(0);
  let [showSponsor, setShowSponsor] = useState({ sponsorAccountId: "", accountName: "" });
  let [showSite, setShowSite] = useState({
    sponsorAccountId: "", accountName: ""
  });


  useEffect(() => {
    let isSiteSelectionPage = checkPermission('S_Site_Selection', 'Read');
    console.log(isSiteSelectionPage, "isSiteSelectionPage")
    if (isSiteSelectionPage === "0") {
      propsobj.history.push('/site/opensquare')
    } else {
      setProgress(true);
      callSponsorsApi(localStorage.getItem('loginUserId'))
      propsobj.UpdateFormField({ name: "autosponsor", value: "" })
      propsobj.UpdateFormField({ name: "autostudy", value: "" })
      propsobj.setSidebarActiveEl('');
      changeImg()
    }
    return () => {
      propsobj.UpdateFormField({ name: "globalsearch", value: true })
    }
  }, [])

  const callSponsorsApi = async (id) => {
    let requestBody = {
      query: `query {
    getSponsorOfStudysOfSite(siteId:"${id}") {
      sponsorAccountId
      accountName
      parentAccountId
      accountType
    }
  }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getSponsorOfStudysOfSite', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      if (res.data.getSponsorOfStudysOfSite.length) {
        //console.log(res.data, "sponsors")
        setSponsors(res.data.getSponsorOfStudysOfSite)
      } else {
        setSponsors([])
      }
    }
  }
  const handleSponsors = async (obj) => {
    setShowSponsor(obj.selected);
    var obj1 = {
      name: obj.name,
      value: obj.selected
    }
    propsobj.UpdateFormField(obj1)
  }

  const changeImg = () => {
    const randomNumber = Math.floor(Math.random() * ImgArr.length);
    setImgSelected(randomNumber)
  }
  const autoPropstoSponsors = {
    'options': sponsors,
    propsobj,
    keys: ['sponsorAccountId', 'accountName'],
    error: '',
    helperText: ''
  }


  const handleClick = async (e) => {
    e.preventDefault()
    //console.log(showSite)

    setProgress(true)
    //security part starts
    let requestBody = {
      query: `query {
          getPartnerId(accountId:"${showSponsor.sponsorAccountId}")
    }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getPartnerId', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      //console.log(res.data.getPartnerId, "patnerid")
      let partnerId = res.data.getPartnerId


      function ip2int(ip) {
        return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
      }
      let url = appConfig.ipInfoApi
      async function getipData(url) {
        const response = await fetch(url);
        return response.json();
      }

      let localId = await getipData(url);
      // alert(JSON.stringify(localId))
      var securityBody = JSON.stringify({ "partnerId": partnerId, "localId": ip2int(localId.ip).toString() });

      let res1 = await securityApi('securityapi', securityBody);
      // setProgress(false);
      if (!res1) {
        alert('Error')
      } else {
        //console.log(res1, "siteselec")
        setProgress(false)
        propsobj.history.push('/pag')

      }
    }
    // security part stops--------------------------------
  }

  const handleSites = async (obj) => {
    setShowSite(obj.selected);
    // dispatch({ type: 'UpdateFormField', name: obj['name'], value: obj.selected });
    var obj1 = {
      name: obj.name,
      value: obj.selected
    }
    propsobj.UpdateFormField(obj1)
    
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div id="wrapper" >
        <div className="contentBody" >
          <Header />
          <div className="row mt-10 mr-0 siteselectioncss">
            <div className="col-md-6">
              <div className="card" >
                <div className="card-body"><img src={HospitalIcon} height="8%" width="8%" />
                  {sponsors.length > 1 ?
                    <form noValidate autoComplete="off" onSubmit={handleClick}>
                      <AutoOptionsNew
                      {...autoPropstoSponsors}
                      autoValue={showSponsor}
                      name="autosponsor"
                      label="Want to select the sponsor?"
                      keyName='accountName'
                      must={'true'}
                      shrink={true}
                      {...propsobj.state}
                      // dispatch={dispatch}
                      callback={handleSponsors} />
                    </form>
                    : sponsors.length > 0 && <div><span>Sponsor Selected:</span><span style={{ color: "#203F68" }}>{sponsors[0].accountName}</span></div>}
                </div>

              </div>
              <br />
              <div className="buttonContainer" >
                {/* {propsobj.formValues.autosite && propsobj.formValues.autosponsor && */}
                  <button className="btn btn-primary-blue btn-rounded btn-small" style={{ float: "right" }} onClick={handleClick}>Next</button>
                  {/* }  */}
                  <br />
              </div>
            </div>
            <div className="col-md-6 siteImg" ><img src={ImgArr[imgSelected]} /></div>


          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeSite));
// export default React.memo(withRouter(ChangeSite));


